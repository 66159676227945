<template>
  <v-container>
    <v-row>
      <v-col class="pa-6">
        <span class="text-h4">
          Twoje konto
        </span>
      </v-col>
      <v-col class="text-right" align-self="center">
        <v-btn class="text-none" @click="logout">Wyloguj się</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-row>
                  <v-col class="text-right" align-self="center">
                    <span class="text-body-1">Imię i nazwisko: </span>
                  </v-col>
                  <v-col>
                    <span class="text-h6">{{firstName}} {{lastName}}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right" align-self="center">
                    <span class="text-body-1">Pesel: </span>
                  </v-col>
                  <v-col>
                    <span class="text-h6">{{pesel}}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right" align-self="center">
                    <span class="text-body-1">Email: </span>
                  </v-col>
                  <v-col>
                    <span class="text-h6">{{email}}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right" align-self="center">
                    <span class="text-body-1">Numer telefonu: </span>
                  </v-col>
                  <v-col>
                    <span class="text-h6">{{cellphone}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<!--    <v-row class="text-right">-->
<!--      <v-col>-->
<!--        <v-btn @click="changePassword" class="mr-2" large>-->
<!--          Zmiana hasła-->
<!--        </v-btn>-->
<!--        <v-btn @click="changeCellphone" class="mr-2" large>-->
<!--          Zmiana numeru telefonu-->
<!--        </v-btn>-->
<!--        <v-btn @click="changeEmail" class="mr-2" large>-->
<!--          Zmiana adresu email-->
<!--        </v-btn>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <cellphone-change/>-->
<!--    <email-change/>-->
<!--    <password-change/>-->
  </v-container>
</template>

<script>
  import CellphoneChange from "../../components/modals/CellphoneChange";
  import EmailChange from "../../components/modals/EmailChange";
  import PasswordChange from "../../components/modals/PasswordChange";
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "User",
    components: { PasswordChange, EmailChange, CellphoneChange },
    computed: mapState({
      firstName: state => state.user.firstName,
      lastName: state => state.user.lastName,
      email: state => state.user.email,
      cellphone: state => state.user.cellphone,
      pesel: state => state.user.pesel,
    }),
    methods: {
      ...mapActions({
        logout: actions.LOGOUT
      }),
      changePassword() {
        this.$store.state.passwordModal = true;
      },
      changeCellphone() {
        this.$store.state.cellphoneModal = true;
      },
      changeEmail() {
        this.$store.state.emailModal = true;
      }
    }
  }
</script>

<style scoped>

</style>
