<template>
  <v-dialog v-model="dialog"
            persistent
            scrollable
            max-width="700px">
    <v-card>
      <v-card-title>
        Ważne! Zmiana maksymalnej kwoty finansowania.
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col style="text-align: justify">
              <span class="text-body-1">
               Informujemy że zmianie uległa maksymalna kwota finansowania. Aktualna możliwa kwota finansowania do wysokości składki 8484,00 zł.
              </span>
              <br/>
              <span class="text-body-1">W razie pytań pozostajemy do Państwa dyspozycji pod numer tel. +48 511810722 oraz adresem email: <a
                href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary"
               @click="okClick">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: "NotificationModal",
    computed: {
      dialog: {
        get() {
          return this.$store.state.user.notification;
        },
        set(val) {
          this.$store.state.user.notification = val;
        }
      },
    },
    methods: {
      okClick() {
        this.dialog = false;
      }
    }
  }
</script>

<style scoped>
  .bold_underscore {
    text-decoration: underline;
    font-weight: bold;
  }
</style>
