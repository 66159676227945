<template>
  <div>
    <div class="text-h6">Ustawianie nowego hasła</div>
    <p>Wpisz poniżej nowe hasło dla konta:<br><span class="text-body-1">{{email}}</span></p>
    <v-text-field outlined
                  ref="pass1"
                  label="Nowe hasło"
                  type="password"
                  v-model="password1"
                  name="password"
                  v-validate="'passwordRequired||passwordLength||password'"
                  :error-messages="errors.first('password')"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"/>
    <v-text-field outlined
                  ref="pass2"
                  label="Powtórz hasło"
                  v-model="password2"
                  type="password"
                  :error-messages="error2"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"/>
    <div class="d-flex justify-space-between mb-2">
      <v-btn class="text-none"
             block
             color="primary"
             x-large
             @click="setPassword">
        Ustaw nowe hasło
      </v-btn>
    </div>
  </div>
</template>

<script>
  import * as actions from "@/store/actions/action-types";
  import { mapActions, mapState } from "vuex";
  import {
    validatePassword,
    validatePasswordLength,
    validatePasswordRequired
  } from "@/validators/fieldValidators";

  export default {
    name: "SignUp",
    created() {
      this.$validator.extend('passwordLength', validatePasswordLength);
      this.$validator.extend('password', validatePassword);
      this.$validator.extend('passwordRequired', validatePasswordRequired);
      const token = this.$route.query.token;
      if (token) {
        this.getEmailForToken(token)
      }
    },
    data() {
      return {
        password1: null,
        password2: null,
        show1: null,
        show2: null,
        error2: null
      }
    },
    computed: mapState({
      email: state => state.userEmail
    }),
    methods: {
      ...mapActions({
        getEmailForToken: actions.GET_EMAIL_FOR_TOKEN_SIGNUP,
        signUp: actions.SIGN_UP
      }),
      setPassword() {
        this.error2 = null;
        this.$validator.validateAll();
        if (!this.password1) {
          this.$refs.pass1.focus();
          return
        }
        if (!/^.{8,}$/.test(this.password1)) {
          this.$refs.pass1.focus();
          return
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/.test(this.password1)) {
          this.$refs.pass1.focus();
          return
        }
        if (!this.password2) {
          this.error2 = "Nie potwierdzono hasła";
          this.$refs.pass2.focus();
          return
        }
        if (this.password1 === this.password2) {
          this.signUp({ token: this.$route.query.token, password: this.password1 });
        } else {
          this.error2 = "Hasła nie są takie same";
          this.$refs.pass2.focus();
        }
      },
    }
  }
</script>

<style scoped>

</style>
