<template>
  <div class="home">
    <left-bar/>
    <router-view/>
    <error-modal/>
    <notification-modal/>
  </div>
</template>

<script>
  import LeftBar from "../../components/bars/LeftBar";
  import ErrorModal from "@/components/modals/ErrorModal";
  import NotificationModal from "@/components/modals/NotificationModal";

  export default {
    name: 'Home',
    components: {
      NotificationModal,
      ErrorModal,
      LeftBar,
    },
  }
</script>
