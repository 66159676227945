<template>
  <v-app :class="partner==='UNI' ? 'unilink' : ''">
    <v-main>
      <router-view/>
      <session-end-modal/>
    </v-main>
  </v-app>
</template>

<script>

  import SessionEndModal from "@/components/modals/SessionEndModal";
  import * as mutations from "@/store/mutations/mutation-types";
  import { mapState } from "vuex";

  export default {
    name: 'App',
    components: {
      SessionEndModal
    },
    created() {
      const host = window.location.hostname;
      if (host.search("unirat") !== -1) {
        this.$store.commit(mutations.SET_PARTNER,"UNI");
      }
      if (host.search("dfs24") !== -1) {
        this.$store.commit(mutations.SET_PARTNER,"DMD");
      }
    },
    computed: mapState(["partner"])
  };
</script>
<style>
  .unilink.v-application .primary {
    background-color: #FF9F38 !important;
    border-color: #FF9F38 !important;
  }

  .unilink.v-application .primary--text {
    color: #FF9F38 !important;
    caret-color: #FF9F38 !important;
  }
  .unilink {
    background-color: #f9f9f9 !important;
  }


  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-ExtraLight.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-ExtraLight.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-Thin.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-Thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-Regular.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-Light.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-Bold.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-Bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-Medium.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Geogrotesque Cyr';
    src: url(~@/assets/fonts/GeogrotesqueCyr-SemiBold.woff2) format('woff2'),
    url(~@/assets/fonts/GeogrotesqueCyr-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
</style>
