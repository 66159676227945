var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.applicationsFiltered,"show-expand":"","custom-filter":_vm.applicationFilter,"expanded":_vm.expanded,"search":_vm.search,"fixed-header":false,"loading":_vm.loading,"no-data-text":"Brak wniosków","no-results-text":"Brak wniosków spełniających kryteria","footer-props":_vm.footerProps},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.type === 'VEHICLE')?_c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.make)+" "+_vm._s(item.model))]),_c('br'),_vm._v(" nr rej. "),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.licensePlate))])]),_c('v-col',{attrs:{"cols":"8"}})],1)],1):_vm._e(),(item.type === 'PROPERTY')?_c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{},[_vm._v(" Typ nieruchomości: "),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.getPropertyType(item.propertyType)))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" Adres: "),_c('span',{staticClass:"text-body-1"},[_vm._v(" ul. "+_vm._s(item.street)+" "+_vm._s(item.house ? item.house : "")+" "),(item.apartment)?[_vm._v("m.")]:_vm._e(),_vm._v(" "+_vm._s(item.apartment ? item.apartment : "")+", "+_vm._s(item.zipCode)+" "+_vm._s(item.city)+" ")],2)])],1)],1):_vm._e()])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'VEHICLE')?_c('v-icon',[_vm._v("mdi-car-outline")]):_c('v-icon',[_vm._v("mdi-home-outline")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateString(item.date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'ACCEPTED')?_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""},on:{"click":function($event){return _vm.prepareFinalize(item)}}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]):_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'ACCEPTED')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.prepareFinalize(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-play-circle-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Kontynuuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'SAVED')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.loadApplication(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-play-circle-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Kontynuuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.hidden == false)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.hideApplication(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-off-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ukryj wniosek")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.hidden === true)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showApplication(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pokaż wniosek")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'FINALIZED')?_c('a',_vm._g(_vm._b({staticClass:"icon-link",on:{"click":function($event){return _vm.getDocument(item.processId, 'signed_contract')}}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-document ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pobierz umowę")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status !== 'REJECTED')?_c('a',_vm._g(_vm._b({staticClass:"icon-link",on:{"click":function($event){return _vm.getDocument(item.processId, 'HPC_SOP')}}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-document-outline ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pobierz kartę produktu")])])]}}])}),_c('form',{staticStyle:{"display":"none"},attrs:{"action":"/api/application/document","method":"POST","name":"documentForm","target":"_blank"}},[_c('input',{attrs:{"id":"documentCode","name":"documentCode","type":"text"}}),_c('input',{attrs:{"id":"processId","name":"processId","type":"text"}})]),_c('v-card-actions',{staticClass:"float-right"},[_c('v-checkbox',{attrs:{"label":"Pokaż ukryte wnioski"},model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }