<template>
  <v-container>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col cols="8">
        <v-card class="pa-4">
          <v-card-text class="pa-2">
            <v-row>
              <v-spacer/>
              <v-col cols="6">
                <div class="text-body-1 mb-2">Wprowadź kod pin podany przez klienta</div>
                <v-text-field v-model="pin"
                              label="Kod PIN"
                              type="text"
                              outlined
                              :error-messages="error"/>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4">
          <v-card-title class="mt-n4 mb-n6">
            Kolejne kroki
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ul>
                  <li v-for="step in nextSteps" class="text-small">{{step}}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="8">
        <v-btn x-large class="mr-2" @click="requestNewPin">Nowy kod pin
        </v-btn>
        <v-btn color="primary" @click="next" x-large>Dalej
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "ConfirmApplication",
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT,
        requestNewPin: actions.REQUEST_NEW_PIN
      }),
    },
    computed: {
      pin: {
        get() {
          return this.$store.state.credit.pinApp
        },
        set(val) {
          this.$store.state.credit.pinApp = val
        }
      }
    },
    data() {
      return {
        error: null,
        nextSteps: [
          "Aby potwierdzić tożsamość Klienta poproś o nr PIN który został do niego wysłany w wiadomości SMS.",
        ]
      }
    }
  }
</script>

<style scoped>

</style>
