<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Użytkownik nieaktywny
      </v-card-title>
      <v-card-text>
        Twoje konto zostało zablokowane przez administratora. Skontaktuj się ze <a href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>.
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="dialog = false">
          Zamknij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "DisabledUserModal",
    computed: {
      dialog: {
        get() {
          return this.$store.state.showDisabledUserdModal
        },
        set(val) {
          this.$store.state.showDisabledUserdModal = val
        }
      },
    },
  }
</script>

<style scoped>

</style>
