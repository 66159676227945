<template>
  <div class="inline">
    <span class="consentText">
      <span v-for="part in description">
        <a v-if="part.type==='link'" :href="part.href" class="link" target="_blank">{{part.link}}</a>
        <span v-else>{{part}}</span>
      </span>
    </span>
    <div v-if="!!descriptionLong">
      <div v-if="isExpanded" class="expand">
        <div v-html="descriptionLong"/>
      </div>
      <div v-else class="expand" @click="expand">
        <span class="orange-text">Rozwiń...</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "consent",
    data() {
      return {
        isExpanded: false
      }
    },
    computed: {
      description() {
        const match = this.consent.description.match(/\[.+?]{.+?}/g);
        if (match !== null) {
          const links = match.map((item) => {
            const mat = item.match(/\[(.+?)]{(.+?)}/);
            return {
              type: "link",
              link: mat[1],
              href: mat[2]
            }
          });
          const textArray = this.consent.description.split(/\[.+?]{.+?}/);
          return textArray.reduce((prev, curr, index, array) => {
            return prev
              .concat([curr])
              .concat(index === array.length - 1 ? [] : [links[index]]);
          }, [])
        } else {
          return [this.consent.description];
        }
      },
      descriptionLong() {
        if (!!this.consent.descriptionLong) {
          return this.md(this.consent.descriptionLong);
        } else {
          return null;
        }
      }
    },
    methods: {
      expand() {
        this.isExpanded = true
      },
      collapse() {
        this.isExpanded = false
      },
    },
    props: {
      consent: {
        type: Object,
        required:
          true
      }
    }
  }
</script>

<style scoped>
  .consentText {
    white-space: pre-wrap;
  }

  .inline {
    display: inline;
  }

  .expand {
    margin-left: 10px;
    margin-top: 5px;
  }
</style>

<style>
  .inline ul ol {
    list-style-type: lower-latin;
  }
</style>
