<template>
  <v-container>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4">
          <v-card-title>
            Decyzja pozytywna
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="text-center">
                <div class="text-h6">Liczba rat</div>
                <div class="text-h5">{{loanPeriod}}</div>
              </v-col>
              <v-col class="text-center">
                <div class="text-h6">Wysokość raty</div>
                <div class="text-h5">{{installmentAmount.toFixed(2)}} zł</div>
              </v-col>
              <v-col class="text-center">
                <div class="text-h6">Pierwsza rata</div>
                <div class="text-h5">{{firstInstallmentDate}}</div>
              </v-col>
              <v-col class="text-center">
                <div class="text-h6">Koszt</div>
                <div class="text-h5">{{(fee*100).toFixed(2)}}%</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4">
          <v-card-title>Dokumenty</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="text-center" v-for="document in documentList">
                <a @click="getDocument(document.code)">
                  <v-icon x-large color="primary">mdi-file-document-outline</v-icon>
                  {{document.name}}
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col cols="8">
        <v-card class="pa-4">
          <v-card-text class="pa-2">
            <v-row>
              <v-spacer/>
              <v-col cols="6">
                <div class="text-body-1 mb-2">Wprowadź kod pin podany przez klienta</div>
                <v-text-field v-model="pin"
                              label="Kod PIN"
                              type="text"
                              outlined
                              :error-messages="error"/>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="mt-1">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4">
          <v-card-title class="mt-n4 mb-n6">
            Kolejne kroki
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ul>
                  <li v-for="step in nextSteps" class="text-small">{{step}}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="8">
        <v-btn x-large class="mr-2" @click="requestNewPin">Nowy kod pin
        </v-btn>
        <v-btn color="primary" @click="next" x-large>Dalej
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
    <form action="/api/application/document" style="display: none" method="POST" name="documentForm"
          target="_blank">
      <input id="documentCode" name="documentCode" type="text">
      <input id="processId" name="processId" type="text">
    </form>
    <contest-modal/>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import ContestModal from "@/components/modals/ContestModal";

  export default {
    name: "ConfirmContract",
    components: { ContestModal },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT,
        requestNewPin: actions.REQUEST_NEW_PIN
      }),
      getDocument(code) {
          document.getElementById("documentCode").value = code;
          document.getElementById("processId").value = this.processId;
          document.documentForm.submit();
      }
    },
    computed: {
      pin: {
        get() {
          return this.$store.state.credit.pinCon
        },
        set(val) {
          this.$store.state.credit.pinCon = val
        }
      },
      ...mapState({
        fee: state => state.decisionData.decisionParameters.fee,
        firstInstallmentDate: state => state.decisionData.decisionParameters.firstInstallmentDate,
        installmentAmount: state => state.decisionData.decisionParameters.installmentAmount,
        loanPeriod: state => state.decisionData.decisionParameters.loanPeriod,
        documentList: state => state.documentList,
        processId: state => state.credit.processId
      })
    },
    data() {
      return {
        error: null,
        nextSteps: [
          "Pożyczkodawca przyznał klientowi pożyczkę na sfinansowanie składki za ubezpieczenie.",
          "Aby zatwierdzić umowę pożyczkową poproś Klienta o nr PIN który został do niego wysłany w wiadomości SMS. Klient nie musi odręcznie podpisywać umowy pożyczkowej.",
          "Dokumenty pożyczkowe zostaną wysłane do Klienta przez pożyczkodawcę na podany adres e-mail. Jeśli Klient nie podał adresu mailowego, wydrukuj Umowę pożyczkową i Kartę produktu w kolejnym kroku",
          "Kliknij „Dalej” aby dokończyć proces i zatwierdzić umowę pożyczkową."
        ]
      }
    }
  }
</script>

<style scoped>
  .text-small {
    font-size: 12px;
  }
</style>
