export default function validateNIP(val) {
  const value = val.split("-").join("").split(" ").join("");

  if (!/^\d{10}$/.test(value)) {
    return false
  }
  const indexValues = [6, 5, 7, 2, 3, 4, 5, 6, 7, null];
  const valueTable = value.split("");
  const calculatedSum = valueTable.reduce((sum, number, index) => {
    if (indexValues[index] === null) {
      return sum;
    }
    return sum + indexValues[index] * number;
  }, 0);
  return calculatedSum % 11 === parseInt(valueTable[9])
}
