<template>
  <v-container class="mb-8">
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Kontynuuj z:</v-card-subtitle>
              <v-card-text>
                <div class="bank-button" @click="chooseBank({bankCode})">
                  <div class="bank" :class="bankCode"></div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Zmień bank na:</v-card-subtitle>
              <v-card-text>
                <v-row v-for="(item, index) in bankList" :key="index">
                  <v-col>
                    <div class="bank-button" @click="chooseBank({bankCode: item})">
                      <div class="bank" :class="item"></div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "BankChooserAll",
    computed: mapState({
      bankCode: state => state.bankCode,
      bankList: state => state.bankList
    }),
    methods: {
      ...mapActions({ chooseBank: actions.CHOOSE_BANK }),
    }
  }
</script>

<style scoped>
  .bank-button {
    height: 120px !important;
    border-color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .bank {
    padding: 5px 5px;
    height: 100%;
    width: 100%;
  }

  .DRR {
    background: url(~@/assets/drobnaratka.png) center no-repeat;
    background-size: contain;
  }

  .WNG {
    background: url(~@/assets/wonga.svg) center no-repeat;
    background-size: contain;
  }
</style>
