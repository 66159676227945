<template>
  <v-container class="mb-8">
    <v-row class="mt-4">
      <v-spacer/>
      <v-col cols="8" v-if="decisionStatus === 'DECLINE'">
        <v-card>
          <v-card-text class="text-center">
            <div class="text-h4 mb-3">Decyzja negatywna</div>
            <span class="text-h5">
              {{rejectionReason}}
            </span>
          </v-card-text>
          <v-card-text>
            <p>Kredyt nie został przyznany.</p>
            <p>Nacisnij przycisk "Dalej" aby wrócić do listy wniosków.</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8" v-else>
        <v-card>
          <v-card-title>
            <v-icon x-large>mdi-check</v-icon>
            Kredyt uruchomiony
          </v-card-title>
          <v-card-text>
            <p>Kredyt został uruchomiony, w ciągu 24 godzin środki zostaną przelane do ubezpieczyciela na podany numer
              konta.</p>
            <p>Nacisnij przycisk "Dalej" aby wrócić do listy wniosków.</p>
            <p>Dziękujemy.</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="8">
        <v-btn color="primary" @click="next" x-large>Dalej
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "Summary",
    computed: mapState({
      decisionStatus: state => state.decisionData.decision,
      rejectionReason: state => state.decisionData.rejectionReason
    }),
    methods: mapActions({
      next: actions.CREDIT_NEXT
    }),
  }
</script>

<style scoped>

</style>
