import {
  validateAmount,
  validateIdentityNumber,
  validateLength,
  validateLettersAndNumbers,
  validateMail,
  validateNIP,
  validateNRB,
  validatePesel as validatePeselBasic,
  validatePhoneNumber as validatePN,
  validateRegex
} from "./basic"


const validateFirstName = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateRegex(value, /^[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ]+([\-][a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ]+)*$/)
};

const validateLastName = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateRegex(value, /^[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ]+([\- ][a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ]+)*$/)
};

const validateStreet = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateRegex(value, /^[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ0-9]+([\-.]?[ ]?[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ0-9]+)*$/)
};

const validateHouse = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateRegex(value, /^[0-9]*([a-zA-Z])*-?[0-9]*([a-zA-Z])*\/?[0-9]*([a-zA-Z])*-?[0-9]*([a-zA-Z])*$/)
};

const validateApartment = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateLettersAndNumbers(value)
};

const validateCity = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateRegex(value, /^[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ0-9]+([\-.]?[ ]?[a-ząćęłńóśźżA-ZĄĆĘŁÓŃŚŹŻ0-9]+)*$/)
};

const validateZipCode = {
  getMessage() {
    return "Kod pocztowy musi mieć formę XX-XXX."
  },
  validate: (value) => validateRegex(value, /^\d{2}-\d{3}$/)
};

const validatePhoneNumber = {
  getMessage() {
    return "Nieprawidłowy nr telefonu, poprawny format to 9 cyfr oraz spacje i \"-\"."
  },
  validate: (value) => validatePN(value)
};

const validateEmail = {
  getMessage() {
    return "Nieprawidłowy adres email."
  },
  validate: (value) => validateMail(value)
};

const validateLicensePlate = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateLettersAndNumbers(value)
};

const validateCreditAmount = {
  getMessage() {
    return "Niepoprawna wartość."
  },
  validate: (value) => validateAmount(value)
};

const validatePesel = {
  getMessage() {
    return "Niepoprawny numer pesel."
  },
  validate: (value) => validatePeselBasic(value)
};

const validatePolicyId = {
  getMessage() {
    return "Niepoprawna wartość."
  },
  validate: (value) => validateRegex(value, /^[0-9a-zA-Z\/\\-]+$/)
};

const validateAccountNumber = {
  getMessage() {
    return "Niepoprawny numer konta."
  },
  validate: (value) => validateNRB(value)
};

const validatePasswordLength = {
  getMessage() {
    return "Hasło musi mieć conajmniej 8 znaków"
  },
  validate: (value) => validateRegex(value, /^.{8,}$/)
};

const validatePassword = {
  getMessage() {
    return "Hasło musi zawierać conajmniej jedną duża literę, jedną małą literę i jedną cyfrę."
  },
  validate: (value) => validateRegex(value, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/)
};

const validatePasswordRequired = {
  getMessage() {
    return "Nie podano hasła."
  },
  validate: (value) => !!value
};

const validateLength20 = {
  getMessage() {
    return "Podana wartość jest zbyt długa."
  },
  validate: (value) => validateLength(value, 20)
};

const validateLength25 = {
  getMessage() {
    return "Podana wartość jest zbyt długa."
  },
  validate: (value) => validateLength(value, 25)
};

const validateLength50 = {
  getMessage() {
    return "Podana wartość jest zbyt długa."
  },
  validate: (value) => validateLength(value, 50)
};

const validateLength100 = {
  getMessage() {
    return "Podana wartość jest zbyt długa."
  },
  validate: (value) => validateLength(value, 100)
};

const validateIdentityDocumentNumber = {
  getMessage() {
    return "Podano błędny numer dowodu osobistego."
  },
  validate: (value) => validateIdentityNumber(value)
};

const validateNipField = {
  getMessage() {
    return "Podany numer NIP jest niepoprawny."
  },
  validate: (value) => validateNIP(value)
};

const validateMonthlyIncome = {
  getMessage() {
    return "Nieprawidłowy format, podana wartość musi być większa lub równa 0."
  },
  validate: (value) => validateAmount(value)
};

const validateMinAmount = {
  getMessage() {
    return "Składka musi być wyższa niż 400 zł."
  },
  validate: (value) => value >= 400
};

const validateMaxAmount = {
  getMessage() {
    return "Składka musi być niższa niż 8484 zł"
  },
  validate: (value) => value <= 8484
};

const validateMake = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateLettersAndNumbers(value)
};

const validateModel = {
  getMessage() {
    return "Nieprawidłowe znaki w polu."
  },
  validate: (value) => validateLettersAndNumbers(value)
};

export {
  validateCreditAmount,
  validateEmail,
  validatePhoneNumber,
  validateStreet,
  validateHouse,
  validateApartment,
  validateCity,
  validateZipCode,
  validateLicensePlate,
  validatePesel,
  validateFirstName,
  validateLastName,
  validatePolicyId,
  validateAccountNumber,
  validatePassword,
  validatePasswordLength,
  validatePasswordRequired,
  validateLength20,
  validateLength25,
  validateLength50,
  validateLength100,
  validateIdentityDocumentNumber,
  validateMake,
  validateModel,
  validateMonthlyIncome,
  validateNipField,
  validateMinAmount,
  validateMaxAmount
}
