export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const FETCH_USERS = 'fetchUsers';
export const FETCH_AGENCIES = 'fetchAgencies';
export const FETCH_APPLICATIONS = 'fetchApplications';
export const FETCH_AGENCY_ACTIVITY_REPORT = 'fetchAgencyActivityReport';
export const FETCH_ACTIVITY_REPORT = 'fetchActivityReport';

export const SIGN_UP = 'signUp';
export const FORGOT_PASSWORD = 'forgotPassword';
export const PASSWORD_RECOVERY = 'passwordRecovery';

export const CREATE_UPDATE_USER = 'createUpdateUser';
export const CREATE_UPDATE_AGENCY = 'createUpdateAgency';
export const SEND_ACTIVATION_EMAIL = 'sendActivationEmail';
export const TOGGLE_USER = 'toggleUser';
export const EDIT_USER = 'editUser';
export const EDIT_AGENCY = 'editAgency';

export const GET_EMAIL_FOR_TOKEN_SIGNUP = 'getEmailForTokenSingUp';
export const GET_EMAIL_FOR_TOKEN_RECOVERY = 'getEmailForTokenPassword';

export const NEW_APPLICATION = 'newApplication';
export const PREPARE_FINALIZE = 'prepareFinalize';

export const CHOOSE_BANK = 'chooseBank';
export const INITIALIZE = "initialize";
export const SUBMIT_CLIENT_DATA = "submitClientData";
export const CONFIRM_APPLICATION = 'confirmApplication';
export const CONFIRM_CONTRACT = 'confirmContract';
export const CANCEL_APPLICATION = 'cancelApplication';
export const REQUEST_NEW_PIN = 'requestNewPin';
export const RETRY_PROCESS = 'retryProcess';

export const VALIDATE_CLIENT_DATA = "validateClientData";
export const VALIDATE_CREDIT_DATA = "validateCreditData";
export const VALIDATE_FINALIZE_DATA = "validateFinalizeData";

export const FIND_COMPANY = "findCompany";

export const SAVE_APPLICATION = 'saveApplication';
export const LOAD_APPLICATION = 'loadApplication';
export const CREDIT_NEXT = 'creditNext';
export const CREDIT_BACK = 'creditBack';

export const HIDE_APPLICATION = 'hideApplication';
export const SHOW_APPLICATION = 'showApplication';

