<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col cols="10" class="mt-10">
        <span class="text-h4">
          Najczęściej zadawane pytania
        </span>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="10" class="mt-5">
        <p>Poniżej znajdziesz odpowiedzi na najczęściej zadawane nam pytania.</p>
        <p>
          Jeżeli nie znajdziesz odpowiedzi, której szukasz skontaktuj się z naszym centrum kontaktu, dostępnym od poniedziałku do piątku w godzinach od 8.00 do 17.00,
          pod numerem telefonu <a>+48 511 810 722</a> lub pod adresem e-mail: <a class="no-style-link" href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>
        </p>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="10">
        <v-expansion-panels>
          <v-expansion-panel v-for="item in faq">
            <v-expansion-panel-header class="text-h6">{{item.question}}</v-expansion-panel-header>
            <v-expansion-panel-content><span class="text-body-1" v-html="item.answer"/> </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import {FAQ} from "@/constants/FAQ";

  export default {
    name: "FAQ",
    data() {
      return {
        faq: FAQ
      }
    }
  }
</script>

<style scoped>

</style>
