export default function validateNRB(value) {
  const NRB = "PL" + value;
  const CODE_LENGTHS = {
    PL: 28
  };

  const iban = String(NRB).toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  let digits;

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, ((letter) => {
    return letter.charCodeAt(0) - 55;
  }));

  return mod97(digits) === 1;
}

function mod97(string) {
  let checksum = string.slice(0, 2), fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}
