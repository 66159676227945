<template>
  <div>
    <div class="text-h6">Jeśli podany email istnieje to link został wysłany</div>
    <p>Sprawdź swoją skrzynkę email i dokończ proces resetu hasła przez wejście w załączony link.</p>
    <p>
      Jeśli nie otrzymałeś wiadomości email skontaktuj się z <a href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>
    </p>
    <div class="d-flex justify-space-between mb-2">
      <v-btn class="text-none"
             block
             color="primary"
             x-large
             @click="returnToLogin">
        Wróc do strony logowania
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ForgotPasswordConfirmation",
    methods: {
      returnToLogin() {
        this.$router.push("/login")
      },
    }
  }
</script>

<style scoped>

</style>
