<template>
  <v-app-bar app light color="white" elevation="0">
    <div class="d-flex justify-center align-center flex-fill" v-if="partner==='UNI'">
      <div class="logo-container mt-5">
        <img src="@/assets/logo_uniratka_blue.svg"
             alt="Unilink Logo"
             width="169"
             style="vertical-align: top;"
             class="">
        <div style="width: fit-content;" class="mt-1 hipro-logo">
          <hr>
          <div style="font-size: 10px; position: relative; top: 0px; display: inline-block" class="">
            powered by
          </div>
          <img src="@/assets/hiPRO_logo.png"
               alt="Hipro Logo"
               width="50"
               style="vertical-align: top; filter: grayscale(100%); margin-top: 3px; opacity: 0.6"
               class="ml-1">
        </div>
      </div>
    </div>
    <div class="d-flex justify-center align-center flex-fill" v-else>
      <img src="@/assets/hiPRO_logo.png"
           alt="Hipro Logo"
           width="122"
           style="vertical-align: middle;"
           class="mr-1">
    </div>
  </v-app-bar>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "TopBar",
    computed: mapState(['partner'])
  }
</script>

<style scoped>
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .hipro-logo {
    color: rgba(104, 104, 106, 70);
  }
</style>
