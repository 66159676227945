<template>
  <div class="login-wrap">
    <div class="background-image-frame">
      <img class="background-image" src="@/assets/unilink_U.svg">
    </div>
  <v-container class="mb-6 mt-6 pa-6">
    <v-row>
      <v-col>
        <v-row :justify="'space-between'" :align="'end'">
          <v-col>
            <span class="finansowanie-title">Finansowanie w</span>
          </v-col>
          <v-col class="text-right">
            <img alt="DrobnaRatka" style="height: 60px;" src="@/assets/drobnaratka_alpha.png">
          </v-col>
        </v-row>
        <hr>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col class="card-hover">
        <v-card @click="chooseBank({bankCode})" class="chooser-card">
          <v-card-text class="text-center">
            <div class="bank-chooser">
              Dowolne TU
            </div>
          </v-card-text>
        </v-card>
        <span class="chooser-subtitle">
          Pomóż Klientowi sfinansować polisę w{{'\xa0'}}dowolnym TU
        </span>
      </v-col>
      <v-col cols="1"/>
      <v-col class="card-hover">
        <v-card @click="chooseBank({bankCode, isTrasti: true})" class="chooser-card">
          <v-card-text class="text-center">
            <div class="bank-chooser">
              Trasti 0%
            </div>
          </v-card-text>
        </v-card>
        <span class="chooser-subtitle">
          Dla Trasti możesz przygotować finansowanie{{'\xa0'}}0% - beż żadnych kosztów dla Klienta
        </span>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "BankChooserUni",
    computed: mapState({
      bankCode: state => state.bankCode,
      bankList: state => state.bankList
    }),
    methods: {
      ...mapActions({ chooseBank: actions.CHOOSE_BANK }),
    }
  }
</script>

<style scoped>
  .finansowanie-title {
    font-size: 30px;
    font-family: "Geogrotesque Cyr", sans-serif;
    font-weight: normal;
  }

  .bank-chooser {
    font-size: 70px;
    font-weight: 600;
    margin-top: 75px;
    margin-bottom: 75px;
    font-family: "Geogrotesque Cyr", sans-serif;
    color: #505051;
  }

  .chooser-subtitle {
    font-size: 26px;
    font-family: "Geogrotesque Cyr", sans-serif;
    font-weight: 300;
  }

  .chooser-card {
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
  }

  .card-hover:hover .chooser-card {
    border-color: #F08108;
  }

  .card-hover:hover .chooser-card .bank-chooser {
    color: #F08108;
  }

  .card-hover:hover .chooser-subtitle {
    color: #F08108;
  }

  .background-image-frame {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .background-image {
    width: auto;
    height: 90%;
    max-width: 92%;
  }

  .login-wrap {
    flex: 1 1 auto;
    max-width: 100%;
    height: 90%;
    position: relative;
    overflow-y: hidden;
  }
</style>
