export const FAQ = [
  {
    question: "Jaką formę płatności należy wybrać w systemie Ubezpieczyciela?",
    answer: "Polisę w systemie Ubezpieczyciela należy wystawić z płatnością jednorazową z rodzajem płatności przelew."
  },
  {
    question: "Jakie dane Klienta należy przekazać, aby sfinansować składkę?",
    answer: "Do wniosku należy wpisać dane osobowe oraz teleadresowe Klienta (Ubezpieczającego) podane do kalkulacji polisy. Należy również wpisać dane pojazdu (numer rejestracyjny, marka, model). Do uruchomienia finansowania należy podać nazwę TU, w którym zawarta jest polisa, numer polisy oraz numer konta do wpłaty składki z finansowanej polisy."
  },
  {
    question: "Jakie polisy można finansować?",
    answer: "Dowolne polisy komunikacyjne oraz majątkowe."
  },
  {
    question: "Czy w trakcie finansowania można przerwać proces i dokończyć później? W jakim terminie?",
    answer: "Tak, po uzyskaniu zgody na finansowanie można zapisać wniosek i uruchomić płatność w późniejszym terminie – do 3 dni roboczych. Lista wniosków z ich statusem widoczna jest po zalogowaniu na ekranie „Twoje wnioski”."
  },
  {
    question: "Jaka jest minimalna / maksymalna kwota finansowania?",
    answer: "Minimalna kwota to 200 zł, maksymalna to 10 000 zł."
  },
  {
    question: "Na ile rat zostanie rozłożona płatność składki?",
    answer: "Płatność składki jest rozłożona na 12 miesięcznych rat. Wysokość poszczególnych rat i terminy płatności są wskazanie w harmonogramie spłaty znajdującym się w umowie o finansowanie oraz karcie produktu."
  },
  {
    question: "Czy możliwe jest częściowe finansowanie składki za ubezpieczenie?",
    answer: "Nie, finansowanie udzielane jest w wysokość 100% składki za ubezpieczenie."
  },
  {
    question: "Jaki jest koszt finansowania dla Klienta?",
    answer: "Oprocentowanie: 0%<br>" +
      "Prowizja za finansowanie: 19,2% (1,6% miesięcznie)<br>" +
      "Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi: 39,6%<br>" +
      "Przykład:<br><ul>" +
      "<li>Składka za ubezpieczenie: 600 zł</li>" +
      "<li>Prowizja za finansowanie: 600 zł * 19,2% = 115,2 zł</li>" +
      "<li>Całkowity kwota do zapłaty: 715,2 zł (składka za ubezpieczenie 600 zł + prowizja 115,2 zł)</li>" +
      "<li>Składka miesięczna: 59,6 zł</li>" +
      "</ul>"
  },
  {
    question: "Jak ustalana jest data płatności raty?",
    answer: "Domyślnie data pierwszej raty jest ustawiona jako 10 dzień kolejnego miesiąca po zawarciu umowy. Przykładowo, jeśli wniosek zostanie złożony 5 marca data płatności pierwszej raty to 10 kwietnia."
  },
  {
    question: "W jakim czasie płatność za składkę trafi do Towarzystwa Ubezpieczeniowego?",
    answer: "Przelew składki nastąpi w przeciągu 2 dni roboczych od zawarcia umowy. Ubezpieczenie zostanie opłacone w całości."
  },
  {
    question: "W jaki sposób spłacać raty (bank, poczta)?",
    answer: "Kwotę raty należy przelać na numer rachunku do spłat wskazany w karcie produktu oraz umowie. Wpłat należy dokonywać w terminach zgodnych z harmonogramem spłat."
  },
  {
    question: "Czy klient może zawiesić spłaty?",
    answer: "Klient spłaca zobowiązanie zgodnie z harmonogramem przedstawionym w umowie. Nie ma możliwości zawieszenia spłaty rat."
  },
  {
    question: "Czy Klient musi podpisać dodatkową umowę?",
    answer: "Klient akceptuje umowę przez podanie sprzedawcy Kodu z wiadomości SMS. Tradycyjny podpis na umowie nie jest wymagany."
  },
  {
    question: "W jaki sposób klient otrzymuje umowę i formularz informacyjny?",
    answer: "Formularz informacyjny najlepiej wydrukować i przekazać Klientowi, zostanie on również wysłany wraz z umową na maila po zawarciu umowy. W punkcie sprzedaży Agent zapoznaje klienta z umową na ekranie komputera oraz ma możliwość jej wydrukowania i wręczenia klientowi."
  },
  {
    question: "Co, jeśli Klient nie ma lub nie chce podawać adresu email?",
    answer: "W przypadku, kiedy nie dysponujemy adresem email Klienta należy wydrukować i wręczyć Klientowi wzory dokumentów. Samo podpisanie umowy nadal odbywa się za pośrednictwem Kodu z SMS, także nie ma potrzeby zbierania fizycznych podpisów na dokumentach."
  },
  {
    question: "Co stanie się z finansowaniem w przypadku sprzedaży pojazdu?",
    answer: "Umowa na finansowanie jest niezależna od umowy ubezpieczenia. Sprzedaż pojazdu nie zamyka umowy finansowania, pozostałe raty należy w dalszym ciągu spłacać zgodnie z harmonogramem (raty nie przechodzą na nabywcę pojazdu). Składki za niewykorzystany okres ubezpieczenia Ubezpieczyciel zwróci bezpośrednio Klientowi (w przypadku OC nastąpi to wyłącznie po przesłaniu wypowiedzenia ubezpieczenia przez nabywcę pojazdu)."
  },
  {
    question: "W jaki sposób zostanie zwrócona niewykorzystana składka z tytułu ubezpieczenia?",
    answer: "Wszelkie zwroty składek z tytułu ubezpieczenia będą zwracane Klientowi przez Ubezpieczyciela, tak jak w standardowych polisach."
  },
  {
    question: "Czy dane Klienta są sprawdzane w BIK?",
    answer: "Tak. Klient musi wyrazić zgodę na sprawdzenie danych w BIK. Wyrażenie zgody na przetwarzanie w BIK jest warunkiem weryfikacji wniosku o finansowanie składki z polisy."
  },
  {
    question: "Czy podmiot finansujący może odmówić finansowania?",
    answer: "W wyniku weryfikacji podmiot finansujący może odmówić finansowania. Klient ma prawo poznać powód odmowy."
  },
  {
    question: "Czy Klient może wielokrotnie wnioskować o raty i co się z tym wiąże?",
    answer: "Tak, klient może wielokrotnie wnioskować o finansowanie. Historia wnioskowania zapisuje się w BIK."
  },
  {
    question: "Co się stanie, jeśli Klient przestanie dokonywać spłat?",
    answer: "Podmiot finansujący skontaktuje się z Klientem, aby wyjaśnić sytuację. Od należności przeterminowanych będą naliczane odsetki zgodnie z treścią zawartej umowy na finansowanie."
  },
  {
    question: "Czy poprawność adresu Klienta należy weryfikować z adresem wpisanym w dowodzie osobistym?",
    answer: "Nie, w procesie przyjmujemy zawsze adres zamieszkania Klienta wiec może on się różnić od adresu zameldowania wpisanego na dowodzie osobistym Klienta."
  },
  {
    question: "Czy Klient może odstąpić od umowy ratalnej?",
    answer: "Tak. Klient może odstąpić od umowy ratalnej w ciągu 14 dni od daty jej zawarcia, poprzez wysłanie odstąpienia do podmiotu, który sfinansował zakup ubezpieczenia. Adres korespondencyjny oraz mailowy a także wzór odstąpienia są wskazane w umowie, którą otrzyma Klient."
  },
  {
    question: "Odstąpienie od umowy ubezpieczenia, a finansowanie.",
    answer: "W przypadku odstąpienia przez Klienta od umowy ubezpieczenia z finansowaniem składki, na mocy Ustawy dochodzi także do odstąpienia od Umowy o finansowania. Klient jest zobowiązany zwrócić do podmiotu finansującego wartość finansowania."
  },
  {
    question: "Czy Klient może wcześniej spłacić finansowanie?",
    answer: "Tak. W celu dokonania wcześniejszej spłaty należy skontaktować się z podmiotem, który sfinansował zakup ubezpieczenia i poinformować o zamiarze wcześniejszej spłaty."
  },
  {
    question: "Czy w przypadku częściowej lub całkowitej spłaty rat przed terminem, Klient zostanie poinformowany przez podmiot finansujący o zamknięciu zobowiązania lub ilości rat pozostałych do spłaty i ich wysokości?",
    answer: "W przypadku wcześniejszej całkowitej spłaty rachunek jest zamykany (bez wiadomości email/sms), natomiast jeśli występuje nadpłata to do Klienta jest wysyłany SMS o dyspozycję zwrotu nadpłaty. Wcześniejsza częściowa spłata, jeśli Klient nie wyda odrębnej dyspozycji, skraca harmonogram przy zachowaniu wysokości raty. Klient otrzymuje wtedy nowy harmonogram spłat na e-mail."
  },
  {
    question: "Jakie podmioty finansujące są dostępne w hiPRO?",
    answer: "<ol>" +
      "<li>Drobna Ratka (Mercury Financial) – FinTech założony pod auspicjami funduszu inwestycyjnego mAccelerator.</li>" +
      "<li>InBank – polski oddział Estońskiego banku.</li>" +
      "<li>Credit Agricole – znany międzynarodowy bank.</li>" +
      "</ol>"
  }
];
