import {
  validateAccountNumber,
  validateApartment,
  validateCity,
  validateCreditAmount,
  validateEmail,
  validateFirstName,
  validateHouse,
  validateLastName, validateLicensePlate, validateMake, validateModel,
  validatePesel,
  validatePhoneNumber, validatePolicyId,
  validateStreet,
  validateZipCode
} from "../fieldValidators"

export default function validateClientData(value) {
  const errorList = [];

  if (!value.policyId || !validatePolicyId.validate(value.policyId)) {
    errorList.push('policyId');
  }

  if (!value.accountNumber || !validateAccountNumber.validate(value.accountNumber)) {
    errorList.push('accountNumber');
  }

  // switch (value.insurer) {
  //   case "PZU": {
  //     //Nie mam
  //     break;
  //   }
  //   case "Warta": {
  //     break;
  //   }
  //   case "Hestia": {
  //     break;
  //   }
  //   case "Ergo Hestia": {
  //     //Nie mam
  //     break;
  //   }
  //   case "Axa Ubezpieczenia": {
  //     // czy Uniqa/Axa czy jakieś inne?
  //     break;
  //   }
  //   case "Compensa": {
  //     break;
  //   }
  //   case "Allianz": {
  //     break;
  //   }
  //   case "Generali": {
  //     break;
  //   }
  //   case "Link4": {
  //     break;
  //   }
  //   case "Wiener": {
  //     break;
  //   }
  //   case "Uniqa": {
  //     // czy Uniqa/Axa czy jakieś inne?
  //     break;
  //   }
  //   case "InterRisk": {
  //     break;
  //   }
  //   case "TUW TUW": {
  //     break;
  //   }
  //   case "TUZ TUW": {
  //     break;
  //   }
  //   case "Aviva": {
  //     //Nie mam
  //     break;
  //   }
  //   case "Concordia": {
  //     //Nie mam
  //     break;
  //   }
  //   case "Pocztowe": {
  //     //Nie mam
  //     break;
  //   }
  //   case "TUW PG": {
  //     //Nie mam
  //     break;
  //   }
  //   case "TUW PZU": {
  //     //Nie mam
  //     break;
  //   }
  //   case "MTU": {
  //     //mam walidator nie mam pasuje do opcji
  //     break;
  //   }
  //   case "Proama": {
  //     //mam walidator nie mam pasuje do opcji
  //     break;
  //   }
  //
  // }

  return errorList;
}
