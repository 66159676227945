import { validateNipField } from "@/validators/fieldValidators";

export default function validateCompanyNip(value) {
  const errorList = [];

  if (!value || !validateNipField.validate(value)) {
    errorList.push('nip');
  }

  return errorList;
}
