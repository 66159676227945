<template>
  <v-navigation-drawer permanent app width="300">
    <div v-if="partner==='UNI'" class="text-center">
      <div>
        <img src="@/assets/logo_uniratka_blue.svg"
             alt="Uni Logo"
             width="200"
             style="vertical-align: middle;" class="mt-10">
      </div>
      <span style="font-size: 10px">powered by</span>
      <div class="mt-n1">
        <img src="@/assets/hiPRO_logo.png"
             alt="Hipro Logo"
             width="70"
             style="vertical-align: middle; filter: grayscale(100%);">
      </div>
    </div>
    <div v-else>
    <img src="@/assets/hiPRO_logo.png"
         alt="Hipro Logo"
         width="122"
         style="vertical-align: middle;"
         class="mr-5 ma-10">
    <img src="@/assets/DSF_logo.png"
         v-if="partner === 'DMD'"
         alt="DFS Logo"
         width="80"
         style="vertical-align: middle;">
    </div>
    <v-list nav v-model="currentRoute" class="ml-5" flat>
      <v-list-item v-for="item in navigation"
                   :key="item.title"
                   :to="item.route"
                   :target="item.href ? '_blank' : ''"
                   :href="item.href"
                   color="primary"
                   link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>


    <template v-slot:append>
      <v-card color="#F2F4F7" outlined class="pl-4 pr-4 pt-2 ml-8 mr-8 mb-1">
        <p class="text-h6">Wsparcie</p>
        <p class="text-body-2">
          <v-icon small>mdi-phone-outline</v-icon>
          +48 511 810 722
        </p>
        <p class="text-body-2">
          <v-icon small>mdi-email-outline</v-icon>
          <a v-if="partner==='UNI'" class="no-style-link" href="mailto:wsparcie@uniratka.pl"> wsparcie@uniratka.pl</a>
          <a v-else class="no-style-link" href="mailto:wsparcie@hipro.pl"> wsparcie@hipro.pl</a>
        </p>
      </v-card>
      <div class="ma-8 ml-10">
        <a @click="user" class="no-style-link">
          <v-icon x-large>mdi-account-circle-outline</v-icon>
          <span class="text-subtitle-2 agent-name ma-2">{{firstName}} {{lastName}}</span>
        </a>
      </div>
      <div class="ma-10">
        <v-btn color="primary" x-large @click="logout" block>Wyloguj</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "LeftBar",
    data() {
      return {

      }
    },
    computed: {
      currentRoute() {
        return this.$route.path
      },
      navigation() {
        return [
          { title: 'Twoje wnioski', icon: 'mdi-file-document-outline', route: '/', uniOnly: false },
          {
            title: 'Częste pytania',
            icon: 'mdi-frequently-asked-questions',
            href: 'https://www.hipro.pl/faq',
            uniOnly: false
          },
          { title: 'Instrukcja', icon: 'mdi-information-outline', href: 'https://caeafe70-4d7d-4cad-a43e-06971f1ac5a3.usrfiles.com/ugd/caeafe_2f0fd907d1d74bc899c7dc5e05b43fbb.pdf', uniOnly: true },
          { title: 'O Ratach', icon: 'mdi-book-open-outline', href: 'https://caeafe70-4d7d-4cad-a43e-06971f1ac5a3.usrfiles.com/ugd/caeafe_2d39b1d0f28443a9bdd1858bce8ac40a.pdf', uniOnly: true },
        ].filter(nav => {
          if (this.partner === "UNI") {
            return true;
          }
          return !nav.uniOnly;
        })
      },
      ...mapState({
        partner: state=> state.partner,
        firstName: state => state.user.firstName,
        lastName: state => state.user.lastName
      })
    },
    methods: {
      ...mapActions({
        logout: actions.LOGOUT
      }),
      user() {
        this.$router.push("/user").catch()
      },
      applications() {
        this.$router.push("/").catch()
      },
    }
  }
</script>

<style scoped>
  p {
    margin-bottom: 12px !important;
  }

  .agent-name {
    position: relative;
    top: 2px;
  }

  .no-style-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
  }

</style>
