<template>
  <v-dialog v-model="dialog"
            persistent
            scrollable
            max-width="800px">
    <v-card>
      <v-card-title>
        KONKURS<span class="text-red ml-1">hiPRO</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h5">Wygrałeś <span class="text-red">{{prizeAmount}} zł</span>! Podaj swój numer telefonu aby otrzymać nagrodę</span>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-col>
              <v-text-field type="text"
                            outlined
                            :hide-details="true"
                            autocomplete="off"
                            v-model="agentCellphone"
                            :maxlength="9"
                            label="Numer telefonu"
                            v-validate="{ regex: /^\d{9}$/ }"/>
            </v-col>
            <v-spacer/>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h6">Klauzula informacyjna</span>
              <template v-if="showAll===false">
                <p>Administratorem danych osobowych jest hiPRO Sp. z o.o. (...) <a
                  @click="showAll = true">Więcej</a>
                </p>
              </template>
              <template v-else>
                <p>
                  Administratorem danych osobowych jest hiPRO Sp. z o.o. z siedzibą w Warszawie, adres: 00-116
                  Warszawa, ul. Świętokrzyska 30/63. Składając niżej niniejszy wniosek upoważniam hiPRO do przetwarzania
                  moich danych osobowych tj.: imienia, nazwiska, numeru telefonu i adresu e-mail, w zakresie niezbędnym
                  hiPRO do obsługi konkursu.
                </p>
                <p>
                  Rezygnacja z usługi i/lub zgłoszenie braku zgody na przetwarzanie danych osobowych można zgłosić w
                  każdej chwili kontaktując się z hiPRO mailowo: <a
                  href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>
                </p>
                <p>Mam świadomość, iż udzielenie ww. zgody jest dobrowolne.</p>
                <p>
                  Przysługuje mi prawo do odwołania powyższych zgód w dowolnym momencie, jednakże będzie to skutkowało
                  brakiem możliwości udziału w konkursie.
                </p>
                <p>
                  <a
                    href="https://caeafe70-4d7d-4cad-a43e-06971f1ac5a3.usrfiles.com/ugd/caeafe_3b368033ea1447a7957c53deef33d4bd.pdf"
                    target="_blank">
                    Regulamin usługi
                  </a>
                </p>
              </template>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-spacer/>
            <v-col cols="9">
              <v-checkbox label="Zapoznałam/em się z klauzulą informacyjną i akceptuję Regulamin"
                          v-model="acceptRegulations"
                          class="mt-0 mb-n4"/>
            </v-col>
            <v-spacer/>
          </v-row>
          <transition name="fadeHeight">
            <v-row v-show="!acceptRegulations" class="text-center">
              <v-col class="text-body-1 text-red">
                Nie podając numeru telefonu i nie akceptując regulaminu rezygnujesz z nagrody!
              </v-col>
            </v-row>
          </transition>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary"
               :class="!acceptRegulations ? '' : 'disabled'" @click="okClick">
          Przejdź dalej
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { CONFIRM_CONTRACT_INNER } from "@/store/actions/apiActions/actionTypes/creditActionTypes"
  import { mapActions } from "vuex";

  export default {
    name: "ContestModal",
    data() {
      return {
        showAll: false
      }
    },
    computed: {
      agentCellphone: {
        get() {
          return this.$store.state.agentCellphone;
        },
        set(val) {
          this.$store.state.agentCellphone = val;
        }
      },
      acceptRegulations: {
        get() {
          return this.$store.state.acceptRegulations;
        },
        set(val) {
          this.$store.state.acceptRegulations = val;
        }
      },
      dialog: {
        get() {
          return this.$store.state.showContestModal;
        },
        set(val) {
          this.$store.state.showContestModal = val;
        }
      },
      prizeAmount: {
        get() {
          const prizeAmount = Number(this.$store.state.credit.loanItem.amount)*0.02;
          return prizeAmount.toFixed(2).replace('.', ',');
        }
      }
    },
    methods: mapActions({
      okClick: CONFIRM_CONTRACT_INNER
    })
  }
</script>

<style scoped>
  .text-red {
    color: #f20316;
  }
</style>
