<template>
  <bank-chooser-uni v-if="partner==='UNI'"/>
  <bank-chooser-all v-else/>
</template>

<script>
  import { mapState } from "vuex";
  import BankChooserAll from "@/views/Credit/BankChooserAll";
  import BankChooserUni from "@/views/Credit/BankChooserUni";

  export default {
    name: "BankChooser",
    components: { BankChooserUni, BankChooserAll },
    computed: mapState({
      partner: state => state.partner
    }),
  }
</script>

<style scoped>
</style>
