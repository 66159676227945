<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-text-field v-model="search"
                    append-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details/>
    </v-card-title>
    <v-data-table :headers="headers"
                  item-key="id"
                  :items="applicationsFiltered"
                  show-expand
                  :custom-filter="applicationFilter"
                  :expanded.sync="expanded"
                  :search="search"
                  :fixed-header="false"
                  :loading="loading"
                  no-data-text="Brak wniosków"
                  no-results-text="Brak wniosków spełniających kryteria"
                  :footer-props="footerProps">
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="pa-4" v-if="item.type === 'VEHICLE'">
            <v-row>
              <v-col>
                <span class="text-body-1">{{item.make}} {{item.model}}</span>
                <br>
                nr rej. <span class="text-body-1">{{item.licensePlate}}</span>
              </v-col>
              <v-col cols="8"/>
            </v-row>
          </div>
          <div class="pa-4" v-if="item.type === 'PROPERTY'">
            <v-row>
              <v-col class="">
                Typ nieruchomości: <span class="text-body-1">{{ getPropertyType(item.propertyType) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                Adres: <span class="text-body-1">
                ul. {{item.street}} {{item.house ? item.house : ""}} <template v-if="item.apartment">m.</template> {{item.apartment ? item.apartment : ""}}, {{item.zipCode}} {{item.city}}
              </span>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>
      <template v-slot:item.type="{ item }">
        <v-icon v-if="item.type === 'VEHICLE'">mdi-car-outline</v-icon>
        <v-icon v-else>mdi-home-outline</v-icon>
      </template>
      <template v-slot:item.date="{ item }">
        {{ getDateString(item.date) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status === 'ACCEPTED'"
                @click="prepareFinalize(item)"
                :color="getColor(item.status)"
                dark>
          {{ getStatus(item.status) }}
        </v-chip>
        <v-chip v-else
                :color="getColor(item.status)"
                dark>
          {{ getStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="prepareFinalize(item)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.status === 'ACCEPTED'">
              mdi-play-circle-outline
            </v-icon>
          </template>
          <span>Kontynuuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="loadApplication(item)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.status === 'SAVED'">
              mdi-play-circle-outline
            </v-icon>
          </template>
          <span>Kontynuuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="hideApplication(item)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.hidden == false">
              mdi-eye-off-outline
            </v-icon>
          </template>
          <span>Ukryj wniosek</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="showApplication(item)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.hidden === true">
              mdi-eye-outline
            </v-icon>
          </template>
          <span>Pokaż wniosek</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a @click="getDocument(item.processId, 'signed_contract')" class="icon-link"
               v-if="item.status === 'FINALIZED'"
               v-on="on"
               v-bind="attrs">
              <v-icon small class="mr-2">
                mdi-file-document
              </v-icon>
            </a>
          </template>
          <span>Pobierz umowę</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a @click="getDocument(item.processId, 'HPC_SOP')" class="icon-link" v-if="item.status !== 'REJECTED'"
               v-on="on"
               v-bind="attrs">
              <v-icon small class="mr-2">
                mdi-file-document-outline
              </v-icon>
            </a>
          </template>
          <span>Pobierz kartę produktu</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <form action="/api/application/document" style="display: none" method="POST" name="documentForm"
          target="_blank">
      <input id="documentCode" name="documentCode" type="text">
      <input id="processId" name="processId" type="text">
    </form>
    <v-card-actions class="float-right">
      <v-checkbox v-model="showHidden" label="Pokaż ukryte wnioski"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "ApplicationList",
    created() {
      this.fetchApplications()
    },
    methods: {
      ...mapActions({
        prepareFinalize: actions.PREPARE_FINALIZE,
        hideApplication: actions.HIDE_APPLICATION,
        showApplication: actions.SHOW_APPLICATION,
        loadApplication: actions.LOAD_APPLICATION,
        fetchApplications: actions.FETCH_APPLICATIONS,
      }),
      getDocument(processId, code) {
        document.getElementById("documentCode").value = code;
        document.getElementById("processId").value = processId;
        document.documentForm.submit();
      },
      getDateString(date) {
        return new Date(date).toLocaleDateString("PL");
      },
      getPropertyType(type) {
        switch (type) {
          case "HOUSE":
            return "Dom";
          case "GARAGE":
            return "Garaż";
          case "COMERCIAL":
            return "Lokal użytkowy";
          case "FLAT":
            return "Mieszkanie";
          case "OTHER":
            return "Inny";
        }
      },
      getColor(status) {
        switch (status) {
          case "SAVED":
            return "cyan";
          case "REJECTED":
            return "red";
          case "ACCEPTED":
            return "blue";
          case "FINALIZED":
            return "green";
          case "ACCEPTED_RESIGNED":
            return "grey";
        }
      },
      getStatus(status) {
        switch (status) {
          case "SAVED":
            return "Zapisany";
          case "REJECTED":
            return "Odrzucony";
          case "ACCEPTED":
            return "Do uruchomienia";
          case "FINALIZED":
            return "Zakończony";
          case "ACCEPTED_RESIGNED":
            return "Anulowany";
        }
      },
      applicationFilter(value, search, item) {
        return value != null &&
          search != null &&
          typeof value !== 'boolean' && (
            this.getStatus(item.status).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
            value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
          )
      }
    },
    computed: {
      applicationsFiltered() {
          return this.applications.filter(item => this.showHidden? true : !item.hidden)
      },
      ...mapState({
        applications: state => state.applicationList,
        loading: state => state.loading.show
      })
    },
    data() {
      return {
        footerProps: {
          "items-per-page-all-text": "Wszystkie",
          "items-per-page-text": "Wniosków na stronie"
        },
        search: '',
        expanded: [],
        showHidden: false,
        headers: [
          { text: 'Data wniosku', value: 'date' },
          { text: 'Imię', value: 'firstName' },
          { text: 'Nazwisko', value: 'lastName' },
          { text: 'Kwota składki', value: 'amount' },
          { text: 'Typ polisy', value: 'type' },
          { text: 'Nr rejestracyjny', value: 'licensePlate' },
          { text: 'Nr polisy', value: 'policyId' },
          { text: 'Status', value: 'status' },
          { text: 'Akcje', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' },
        ],
      }
    },
  }
</script>

<style scoped>
  .icon-link {
    text-decoration: none;
  }
</style>
