<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Zmiana numeru telefonu
      </v-card-title>
      <v-card-subtitle>
        Podaj hasło aby zatwierdzić zmianę.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-spacer/>
          <v-col cols="10">
            <v-text-field outlined
                          ref="email"
                          label="Nowy numer telefonu"
                          type="text"
                          v-model="cellphone"
                          :error-messages="cellphone_error"/>
            <v-text-field outlined
                          ref="pass"
                          label="Hasło"
                          type="password"
                          v-model="password"
                          :error-messages="error"/>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialog = false">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               @click="dialog = false">
          Zatwierdź
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "CellphoneChange",
    data() {
      return {
        password: null,
        cellphone: null,
        error: null,
        cellphone_error: null,
      }
    },
    computed: {
      dialog: {
        get() {
          return this.$store.state.cellphoneModal
        },
        set(val) {
          this.$store.state.cellphoneModal = val
        }
      }
    }
  }
</script>

<style scoped>

</style>
