<template>
  <v-container class="mb-8">
    <v-row class="mt-1">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4">
          <v-card-title class="mt-n4 mb-n6">
            Ważna informacja
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ul>
                  <li v-for="step in nextSteps" class="text-small">{{step}}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="8">
        <v-btn x-large @click="save">Zapisz wniosek i wróć do listy
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="bankCode==='DRR'">
      <v-spacer/>
      <v-col align-seft="center" cols="8">
        <v-card class="pa-4" style="color: #f20316">
          <v-card-title class="mt-n4 mb-n6">
            UWAGA! Powiadom o tym Klienta!
          </v-card-title>
          <v-card-text style="color: #f20316">
            <v-row>
              <v-col>
                Zależy nam na bezpieczeństwie transakcji, dlatego po zakupie polisy przedstawiciele Drobnej Ratki
                skontaktują się z Klientem telefonicznie, aby potwierdzić przelew składki.
                <br>
                <br>
                <span style="font-size: 16px">Koniecznie uprzedź Klienta o połączeniu z numeru 799 361 025.</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <div class="text-h4 mb-8 mt-4">Dane polisy</div>
        <v-text-field v-model="policyId"
                      label="Numer polisy"
                      type="text"
                      outlined
                      :error-messages="errors.first('policyId')"
                      name="policyId"
                      v-validate="'required||policyId'"/>
        <v-text-field v-model="accountNumber"
                      label="Numer konta polisy"
                      type="text"
                      name="accountNumber"
                      outlined
                      :error-messages="errors.first('accountNumber')"
                      v-validate="'required||accountNumber'"/>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <v-btn color="primary" @click="next" x-large>Dalej
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import { EventBus } from "@/components/event-bus";
  import { validateAccountNumber, validatePolicyId } from "@/validators/fieldValidators";
  import { insurerOptions } from "@/constants";
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "FinalizeApplication",
    created() {
      this.$validator.extend('accountNumber', validateAccountNumber);
      this.$validator.extend('policyId', validatePolicyId);
      EventBus.$on("validate", () => {
        this.$validator.validateAll();
      });
    },
    computed: {
      policyId: {
        get() {
          return this.$store.state.credit.policyId;
        },
        set(val) {
          this.$store.state.credit.policyId = val;
        }
      },
      accountNumber: {
        get() {
          return this.$store.state.credit.accountNumber;
        },
        set(val) {
          this.$store.state.credit.accountNumber = val;
        }
      },
      ...mapState({
        bankCode: state => state.bankCode
      }),
      insurerList() {
        if (this.$store.state.credit.loanItem.insuranceType === "VEHICLE") {
          return insurerOptions.vehicle;
        } else {
          return insurerOptions.property
        }
      }
    },
    data() {
      return {
        nextSteps: [
          "Aby zatwierdzić płatność w 12 ratach wpisz numer zawartej polisy oraz numer konta z polisy.",
          "Jeżeli chcesz zapisać wniosek, kliknij „Zapisz wniosek” i dokończ proces po zamknięciu polisy."
        ]
      }
    },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT
      }),
      save() {
        this.$router.push("/").catch(() => {
        });
      }
    }
  }
</script>

<style scoped>

</style>
