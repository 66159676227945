<template>
  <div class="login-wrap">
    <div v-if="partner==='UNI'" class="background-image-frame">
      <img class="background-image" src="@/assets/unilink_U.svg">
    </div>
    <v-container class="fill-height justify-center" fluid>
      <v-card class="auth-card"
              :class="{ signup: $route.name === 'signup' }"
              style=""
              outlined
              elevation="2"
              :disabled="loading">
        <v-progress-linear v-if="loading"
                           indeterminate
                           absolute/>
        <div class="login-card">
          <div class="text-center mb-10" v-if="partner !== 'UNI'">
          <span class="text-h6 text--secondary">
            <img src="@/assets/hiPRO_logo.png"
                 alt="Hipro Logo"
                 width="122"
                 style="vertical-align: middle;"
                 class="mr-5">
            <img src="@/assets/DSF_logo.png"
                 v-if="partner === 'DMD'"
                 alt="DFS Logo"
                 width="80"
                 style="vertical-align: middle;"
                 class="mr-n2">
          </span>
          </div>
          <div class="text-center mb-10" v-else>
          <span class="text-h6 text--secondary">
            <div>
            <img src="@/assets/logo_uniratka_blue.svg"
                 alt="DFS Logo"
                 width="254"
                 style="vertical-align: middle;">
            </div>
            <span style="font-size: 10px">powered by</span>
            <div class="mt-n2">
              <img src="@/assets/hiPRO_logo.png"
                   alt="Hipro Logo"
                   width="70"
                   style="vertical-align: middle; filter: grayscale(100%);">
            </div>
          </span>
          </div>
          <v-card-text>
            <router-view/>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "AuthModal",
    computed: {
      ...mapState({
        loading: state => state.loading.show,
        partner: state => state.partner
      }),
    }
  }
</script>

<style scoped lang="scss">
  .auth-card {
    width: 566px;
    overflow: hidden;
  }
  .unilink .auth-card {
    width: 480px;
    overflow: hidden;
  }

  .login-card {
    padding: 80px 100px 64px;
  }

  .unilink .login-card {
    padding: 80px 80px 64px;
  }

  .background-image-frame {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .background-image {
    width: auto;
    height: 90%;
    max-width: 92%;
  }

  .login-wrap {
    flex: 1 1 auto;
    max-width: 100%;
    height: 100%;
    position: relative;
  }

</style>
