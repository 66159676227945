<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Zmiana adresu email
      </v-card-title>
      <v-card-subtitle>
        Podaj hasło aby zatwierdzić zmianę.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-spacer/>
          <v-col cols="10">
            <v-text-field outlined
                          ref="email"
                          label="Nowy email"
                          type="text"
                          v-model="email"
                          :error-messages="email_error"/>
            <v-text-field outlined
                          ref="pass"
                          label="Hasło"
                          type="password"
                          v-model="password"
                          :error-messages="error"/>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialog = false">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               @click="dialog = false">
          Zatwierdź
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "EmailChange",
    data() {
      return {
        password: null,
        email: null,
        error: null,
        email_error: null,
      }
    },
    computed: {
      dialog: {
        get() {
          return this.$store.state.emailModal
        },
        set(val) {
          this.$store.state.emailModal = val
        }
      }
    }
  }
</script>

<style scoped>

</style>
