<template>
  <div>
    <div class="text-h6">Zmiana hasła nie powiodła się</div>
    <p>Twój link do zmiany hasła wygasł. Wygeneruj nowy link na stronie logowania lub skontaktuj się z <a class="no-style-link" href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>.</p>
  </div>
</template>

<script>
  export default {
    name: "RecoveryFailure",
  }
</script>

<style scoped>

</style>
