<template>
  <div>
    <v-form @submit.prevent="next" class="mb-10 text-right">
      <v-text-field ref="log"
                    v-model="email"
                    class="mb-2"
                    label="Email"
                    name="email"
                    type="text"
                    hide-details="auto"
                    outlined
                    :disabled="loading"
                    :error-messages="emailError"/>
      <v-text-field ref="pass"
                    v-model="password"
                    class="mb-2"
                    label="Hasło"
                    name="password"
                    type="password"
                    hide-details="auto"
                    outlined
                    :disabled="loading"
                    v-on:keyup.enter="login"
                    :error-messages="passwordError"/>
      <a href="#" class="text-body-2 text-decoration-none font-weight-bold mb-8 text-accent text--secondary"
         @click="recoverPassword">
        Przypomnij hasło
      </a>
    </v-form>
    <div class="d-flex justify-space-between">
      <v-btn class="text-none"
             block
             color="primary"
             x-large
             depressed
             @click="login">
        Zaloguj
      </v-btn>
    </div>
    <disabled-user-modal/>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import DisabledUserModal from "@/components/modals/DisabledUserModal";

  export default {
    name: "Login",
    components: { DisabledUserModal },
    data: () => ({
      password: null,
      email: null
    }),
    computed: {
      emailError: {
        get() {
          return this.$store.state.loginError.email;
        },
        set(val) {
          this.$store.state.loginError.email = val
        }
      },
      passwordError: {
        get() {
          return this.$store.state.loginError.password;
        },
        set(val) {
          this.$store.state.loginError.password = val
        }
      },
      ...mapState({
        loading: state => state.loading.show
      })
    },
    methods: {
      recoverPassword() {
        this.$router.push("/zapomnialem_hasla").catch();
      },
      ...mapActions({
        loginAction: actions.LOGIN
      }),
      login() {
        this.emailError = null;
        this.passwordError = null;
        if (this.email === null || this.email.trim() === "") {
          this.emailError = 'Nie podano loginu';
          this.$refs.log.focus();
          return
        }
        if (this.password === null || this.password.trim() === "") {
          this.passwordError = 'Nie podano hasła';
          this.$refs.pass.focus();
          return
        }
        this.loginAction({ email: this.email, password: this.password })
      }
    },
    beforeDestroy() {
      this.password = null;
      this.email = null;
    }
  }
</script>
