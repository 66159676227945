export default [
  { code: "PL", name: "Polska" },
  { code: "AF", name: "Afganistan" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algieria" },
  { code: "AD", name: "Andora" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarktyka" },
  { code: "AG", name: "Antigua i Barbuda" },
  { code: "AN", name: "Antyle Holenderskie" },
  { code: "SA", name: "Arabia Saudyjska" },
  { code: "AR", name: "Argentyna" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbejdżan" },
  { code: "BS", name: "Bahamy" },
  { code: "BH", name: "Bahrajn" },
  { code: "BD", name: "Bangladesz" },
  { code: "BB", name: "Barbados" },
  { code: "BE", name: "Belgia" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermudy" },
  { code: "BT", name: "Bhutan" },
  { code: "BY", name: "Białoruś" },
  { code: "BA", name: "Bośnia i Hercegowina" },
  { code: "BO", name: "Boliwia" },
  { code: "BW", name: "Botswana" },
  { code: "BR", name: "Brazylia" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "IO", name: "Brytyjskie Terytorium Oceanu Indyjskiego" },
  { code: "BG", name: "Bułgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "Chiny" },
  { code: "HR", name: "Chorwacja (nazwa lokalna: Hrvatska)" },
  { code: "CY", name: "Cypr" },
  { code: "TD", name: "Czad" },
  { code: "CZ", name: "Czechy" },
  { code: "DJ", name: "Dżibuti" },
  { code: "DK", name: "Dania" },
  { code: "DM", name: "Dominika" },
  { code: "DO", name: "Dominikana" },
  { code: "EG", name: "Egipt" },
  { code: "EC", name: "Ekwador" },
  { code: "ER", name: "Erytrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Etiopia" },
  { code: "FK", name: "Falklandy (Malwiny)" },
  { code: "RU", name: "Federacja Rosyjska" },
  { code: "FJ", name: "Fidżi" },
  { code: "PH", name: "Filipiny" },
  { code: "FI", name: "Finlandia" },
  { code: "FR", name: "Francja" },
  { code: "TF", name: "Francuskie Terytoria Południowe" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Grecja" },
  { code: "GD", name: "Grenada" },
  { code: "GL", name: "Grenlandia" },
  { code: "GE", name: "Gruzja" },
  { code: "GU", name: "Guam" },
  { code: "GY", name: "Gujana" },
  { code: "GF", name: "Gujana Francuska" },
  { code: "GP", name: "Gwadelupa" },
  { code: "GT", name: "Gwatemala" },
  { code: "GN", name: "Gwinea" },
  { code: "GQ", name: "Gwinea Równikowa" },
  { code: "GW", name: "Gwinea-Bissau" },
  { code: "HT", name: "Haiti" },
  { code: "ES", name: "Hiszpania" },
  { code: "NL", name: "Holandia" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hongkong SAR" },
  { code: "IN", name: "Indie" },
  { code: "ID", name: "Indonezja" },
  { code: "IQ", name: "Irak" },
  { code: "IR", name: "Iran (Islamska Republika)" },
  { code: "IE", name: "Irlandia" },
  { code: "IS", name: "Islandia" },
  { code: "IL", name: "Izrael" },
  { code: "JM", name: "Jamajka" },
  { code: "JP", name: "Japonia" },
  { code: "YE", name: "Jemen" },
  { code: "JO", name: "Jordania" },
  { code: "YU", name: "Jugosławia" },
  { code: "KY", name: "Kajmany" },
  { code: "KH", name: "Kambodża" },
  { code: "CM", name: "Kamerun" },
  { code: "CA", name: "Kanada" },
  { code: "QA", name: "Katar" },
  { code: "KZ", name: "Kazachstan" },
  { code: "KE", name: "Kenia" },
  { code: "KG", name: "Kirgistan" },
  { code: "KI", name: "Kiribati" },
  { code: "CO", name: "Kolumbia" },
  { code: "KM", name: "Komory" },
  { code: "CG", name: "Kongo" },
  { code: "CD", name: "Kongo (Demokratyczna Republika Konga)" },
  { code: "KP", name: "Koreańska Republika Ludowo-Demokratyczna" },
  { code: "KR", name: "Korea, Republika" },
  { code: "CR", name: "Kostaryka" },
  { code: "CU", name: "Kuba" },
  { code: "KW", name: "Kuwejt" },
  { code: "LA", name: "Laotańska Republika Ludowo-Demokratyczna" },
  { code: "LS", name: "Lesotho" },
  { code: "LB", name: "Liban" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libijska Arabska Dżamahiria" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Litwa" },
  { code: "LU", name: "Luksemburg" },
  { code: "LV", name: "Łotwa" },
  { code: "MK", name: "Macedonia, Była Jugosłowiańska Republika" },
  { code: "MG", name: "Madagaskar" },
  { code: "MO", name: "Makau SAR" },
  { code: "MW", name: "Malawi" },
  { code: "MV", name: "Malediwy" },
  { code: "MY", name: "Malezja" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MA", name: "Maroko" },
  { code: "MQ", name: "Martynika" },
  { code: "MR", name: "Mauretania" },
  { code: "MU", name: "Mauritius" },
  { code: "MX", name: "Meksyk" },
  { code: "FM", name: "Mikronezja, Sfederowane Stany" },
  { code: "MD", name: "Mołdawia, Republika" },
  { code: "MC", name: "Monako" },
  { code: "MN", name: "Mongolia" },
  { code: "MS", name: "Montserrat" },
  { code: "MZ", name: "Mozambik" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "DE", name: "Niemcy" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NI", name: "Nikaragua" },
  { code: "NU", name: "Niue" },
  { code: "NO", name: "Norwegia" },
  { code: "NC", name: "Nowa Kaledonia" },
  { code: "NZ", name: "Nowa Zelandia" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua Nowa Gwinea" },
  { code: "PY", name: "Paragwaj" },
  { code: "PE", name: "Peru" },
  { code: "PN", name: "Pitcairn" },
  { code: "PF", name: "Polinezja Francuska" },
  { code: "PR", name: "Portoryko" },
  { code: "PT", name: "Portugalia" },
  { code: "CF", name: "Republika Środkowoafrykańska" },
  { code: "ZA", name: "Republika Południowej Afryki" },
  { code: "RO", name: "Rumunia" },
  { code: "RW", name: "Rwanda" },
  { code: "SK", name: "Słowacja (Republika Słowacka)" },
  { code: "SI", name: "Słowenia" },
  { code: "EH", name: "Sahara Zachodnia" },
  { code: "KN", name: "Saint Kitts i Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "VC", name: "Saint Vincent i Grenadyny" },
  { code: "SV", name: "Salwador" },
  { code: "WS", name: "Samoa" },
  { code: "AS", name: "Samoa Amerykańskie" },
  { code: "SM", name: "San Marino" },
  { code: "SN", name: "Senegal" },
  { code: "SC", name: "Seszele" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapur" },
  { code: "SO", name: "Somalia" },
  { code: "LK", name: "Sri Lanka" },
  { code: "PM", name: "St. Pierre i Miquelon" },
  { code: "US", name: "Stany Zjednoczone" },
  { code: "UM", name: "Stany Zjednoczone - wyspy zewnętrzne" },
  { code: "VA", name: "Stolica Apostolska (Państwo Watykańskie)" },
  { code: "SZ", name: "Suazi" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Surinam" },
  { code: "SY", name: "Syryjska Republika Arabska" },
  { code: "CH", name: "Szwajcaria" },
  { code: "SE", name: "Szwecja" },
  { code: "SH", name: "Święta Helena" },
  { code: "TJ", name: "Tadżykistan" },
  { code: "TH", name: "Tajlandia" },
  { code: "TW", name: "Tajwan" },
  { code: "TZ", name: "Tanzania, Zjednoczona Republika" },
  { code: "TL", name: "Timor Wschodni" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trynidad i Tobago" },
  { code: "TN", name: "Tunezja" },
  { code: "TR", name: "Turcja" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraina" },
  { code: "UY", name: "Urugwaj" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "HU", name: "Węgry" },
  { code: "IT", name: "Włochy" },
  { code: "VE", name: "Wenezuela" },
  { code: "GB", name: "Wielka Brytania" },
  { code: "VN", name: "Wietnam" },
  { code: "CI", name: "Wybrzeże Kości Słoniowej (Côte d'Ivoire)" },
  { code: "CX", name: "Wyspa Bożego Narodzenia" },
  { code: "BV", name: "Wyspa Bouvet" },
  { code: "YT", name: "Wyspa Majotta" },
  { code: "NF", name: "Wyspa Norfolk" },
  { code: "RE", name: "Wyspa Reunion" },
  { code: "ST", name: "Wyspy Świętego Tomasza i Książęca" },
  { code: "CK", name: "Wyspy Cooka" },
  { code: "VG", name: "Wyspy Dziewicze (Brytyjskie)" },
  { code: "VI", name: "Wyspy Dziewicze (Stanów Zjednoczonych)" },
  { code: "HM", name: "Wyspy Heard i Mc Donald" },
  { code: "CC", name: "Wyspy Kokosowe (Wyspy Keelinga)" },
  { code: "MP", name: "Wyspy Mariany Północne" },
  { code: "MH", name: "Wyspy Marshalla" },
  { code: "FO", name: "Wyspy Owcze" },
  { code: "SB", name: "Wyspy Salomona" },
  { code: "GS", name: "Wyspy South Georgia i The South Sandwich" },
  { code: "SJ", name: "Wyspy Svalbard i Jan Mayen" },
  { code: "TC", name: "Wyspy Turks i Caicos" },
  { code: "WF", name: "Wyspy Wallis i Futuna" },
  { code: "CV", name: "Wyspy Zielonego Przylądka" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
  { code: "AE", name: "Zjednoczone Emiraty Arabskie" }
];
