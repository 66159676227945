import * as types from './actionTypes/adminActionTypes'
import * as mutations from "@/store/mutations/mutation-types";
import { HTTP } from "@/services/http-common";

const adminActions = {
  [types.CREATE_USER]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/createUser',
      {
        firstName: state.newUser.firstName,
        lastName: state.newUser.lastName,
        pesel: state.newUser.pesel,
        cellphone: state.newUser.cellphone,
        email: state.newUser.email,
        code: state.newUser.salesmanId,
        agencyCode: state.newUser.agencyId.code,
      })
      .then(response => {
        state.newUser = {
          id: null,
          firstName: "",
          lastName: "",
          email: "",
          cellphone: "",
          pesel: "",
          salesmanId: "",
          agencyId: "",
        };
        dispatch(types.FETCH_USERS);
        commit(mutations.HIDE_LOADER);
        commit(mutations.HIDE_CREATE_USER_MODAL);
      })
      .catch(e => {
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.CREATE_AGENCY]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/createAgency',
      {
        name: state.newAgency.name,
        code: state.newAgency.code,
        productCode: state.newAgency.productCode,
        isBusinessAvailable: state.newAgency.businessAvailable,
        isEmailRequired: state.newAgency.isEmailRequired,
        baseUrl: state.newAgency.baseUrl,
      })
      .then(response => {
        state.newAgency = {
          id: null,
          name: "",
          code: "",
          productCode: "",
          isBusinessAvailable: false,
          isEmailRequired: false,
          baseUrl: "https://hipro12.pl",
        };
        dispatch(types.FETCH_AGENCIES);
        commit(mutations.HIDE_LOADER);
        commit(mutations.HIDE_CREATE_AGENCY_MODAL);
      })
      .catch(e => {
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.EDIT_USER]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/editUser',
      {
        id: state.newUser.id,
        firstName: state.newUser.firstName,
        lastName: state.newUser.lastName,
        pesel: state.newUser.pesel,
        cellphone: state.newUser.cellphone,
        email: state.newUser.email,
        code: state.newUser.salesmanId,
        agencyCode: state.newUser.agencyId,
      })
      .then(response => {
        state.newUser = {
          id: null,
          firstName: "",
          lastName: "",
          email: "",
          cellphone: "",
          pesel: "",
          salesmanId: "",
          agencyId: "",
        };
        dispatch(types.FETCH_USERS);
        commit(mutations.HIDE_LOADER);
        commit(mutations.HIDE_CREATE_USER_MODAL);
      })
      .catch(e => {
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },

  [types.EDIT_AGENCY]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/editAgency',
      {
        id: state.newAgency.id,
        name: state.newAgency.name,
        code: state.newAgency.code,
        productCode: state.newAgency.productCode,
        isBusinessAvailable: state.newAgency.businessAvailable,
        isEmailRequired: true,
        baseUrl: state.newAgency.baseUrl,
      })
      .then(response => {
        state.newAgency = {
          id: null,
          name: "",
          code: "",
          productCode: "",
          isBusinessAvailable: false,
          isEmailRequired: true,
          baseUrl: "https://hipro12.pl",
        };
        dispatch(types.FETCH_AGENCIES);
        commit(mutations.HIDE_LOADER);
        commit(mutations.HIDE_CREATE_AGENCY_MODAL);
      })
      .catch(e => {
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.DISABLE_USER]({ commit, state, dispatch }, userId) {
    HTTP.post('/api/admin/disableUser', { userId, status: "INACTIVE" })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus !== 'OK') {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        } else {
          dispatch(types.FETCH_USERS)
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.ENABLE_USER]({ commit, state, dispatch }, userId) {
    HTTP.post('/api/admin/enableUser', { userId, status: "ACTIVE" })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus !== 'OK') {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        } else {
          dispatch(types.FETCH_USERS)
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.SEND_ACTIVATION_LINK]({ commit, state, dispatch }, userId) {
    HTTP.post('/api/admin/sendActivationLink', { userId: userId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus !== 'OK') {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.FETCH_USERS]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/getSalesmanList', {})
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          state.users = response.data.salesmanList
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.FETCH_AGENCIES]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/getAgencyList', {})
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          state.agencyList = response.data.agencyList
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.FETCH_ACTIVITY_REPORT]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/getActivityReport', {})
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          state.activityReport = response.data.activityReportList
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
  [types.FETCH_AGENCY_ACTIVITY_REPORT]({ commit, state, dispatch }) {
    HTTP.post('/api/admin/getAgencyActivityReport', {})
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          state.agencyActivityReport = response.data.activityReportList
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Coś poszło nie tak"])
      })
  },
};

export default adminActions;
