export const stepList = [
  {
    step: "DRAW_BANK",
    stepName: "Dane klienta",
    display: true,
    backAvailable: true,
    cancelAvailable: false,
    currentView: "ClientData",
  },
  {
    step: "INIT_APPLICATION",
    stepName: "Wybór banku",
    display: false, //true,
    backAvailable: true,
    cancelAvailable: true,
    currentView: "",
  },
  {
    step: "BEGIN_APPLICATION",
    stepName: "Wniosek",
    display: true,
    backAvailable: true,
    cancelAvailable: true,
    currentView: "",
  },
  {
    step: "CONFIRM_APPLICATION",
    stepName: "Potwierdzenie wniosku",
    display: true,
    backAvailable: true,
    cancelAvailable: true,
    currentView: "ConfirmApplication",
  },
  {
    step: "WAIT_FOR_DECISION",
    stepName: null,
    display: false,
    stepperInfo: true,
    backAvailable: true,
    cancelAvailable: true,
    currentView: null,
  },
  {
    step: "CONFIRM_CONDITIONS",
    stepName: null,
    display: false,
    backAvailable: true,
    cancelAvailable: true,
    currentView: null,
  },
  {
    step: "WAIT_FOR_CONTRACT",
    stepName: null,
    display: false,
    stepperInfo: true,
    backAvailable: false,
    cancelAvailable: true,
    currentView: null,
  },
  {
    step: "CONFIRM_CONTRACT",
    stepName: "Potwierdzenie umowy",
    display: true,
    backAvailable: false,
    cancelAvailable: true,
    currentView: "ConfirmContract",
  },
  {
    step: "FINALIZE",
    stepName: "Finalizacja umowy",
    display: true,
    backAvailable: false,
    cancelAvailable: true,
    currentView: "FinalizeApplication",
  },
  {
    step: "FINISHED",
    stepName: null,
    display: false,
    stepperInfo: true,
    backAvailable: false,
    cancelAvailable: false,
    currentView: "Summary",
  },
  {
    step: "REJECTED",
    stepName: null,
    display: false,
    stepperInfo: true,
    backAvailable: false,
    cancelAvailable: false,
    currentView: "Summary",
  },

];

export const stepperSteps = stepList.filter(({ display, stepperInfo }) => display || stepperInfo).map(({ step, stepName, backAvailable, cancelAvailable, display }) => {
  return {
    step, stepName, backAvailable, cancelAvailable, display
  }
});

export const navigationSteps = stepList.reduce((prev, curr, index, array) => {
  return prev.concat({
    step: curr.step,
    currentView: curr.currentView,
    nextStep: array[index + 1] ? array[index + 1].step : null,
    nextView: array[index + 1] ? array[index + 1].currentView : null,
  })
}, []);


