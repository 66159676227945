<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col cols="6" class="mt-8">
        <v-card>
          <v-card-title>Dane na polisie</v-card-title>
          <template v-if="credit.clientType !== 'PRIVATE'">
            <v-card-subtitle class="text-body-1">Działalność gospodarcza:</v-card-subtitle>
            <v-card-text class="mb-n8 text--primary">
              <v-row>
                <v-col>
                  <p>{{credit.companyData.name}}</p>
                  <p>NIP: {{credit.companyData.nip}}</p>
                </v-col>
                <v-col>
                  <p>Adres:<br>
                    {{credit.companyData.street}} {{credit.companyData.house ? credit.companyData.house : ""}}
                    <template v-if="credit.companyData.apartment">m.</template>
                    {{credit.companyData.apartment ? credit.companyData.apartment : ""}}
                    <br>
                    {{credit.companyData.zipCode}} {{credit.companyData.city}}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <v-card-subtitle class="text-body-1">Klient:</v-card-subtitle>
          <v-card-text class="pb-0 text--primary">
            <v-row>
              <v-col>
                <p>{{credit.clientData.firstName}} {{credit.clientData.lastName}}</p>
                <p>Adres:<br>
                  ul. {{credit.clientData.mainAddress.street}} {{credit.clientData.mainAddress.house ?
                  credit.clientData.mainAddress.house : ""}}
                  <template v-if="credit.clientData.mainAddress.apartment">m.</template>
                  {{credit.clientData.mainAddress.apartment ? credit.clientData.mainAddress.apartment : ""}}<br>
                  {{credit.clientData.mainAddress.zipCode}} {{credit.clientData.mainAddress.city}}
                </p>
                <p v-if="credit.clientData.differentCorrespondence">Adres korespondencyjny:
                  ul. {{credit.clientData.correspondenceAddress.street}} {{credit.clientData.correspondenceAddress.house
                  ? credit.clientData.correspondenceAddress.house : ""}}
                  <template v-if="credit.clientData.correspondenceAddress.apartment">m.</template>
                  {{credit.clientData.correspondenceAddress.apartment ?
                  credit.clientData.correspondenceAddress.apartment : ""}}
                  {{credit.clientData.correspondenceAddress.zipCode}} {{credit.clientData.correspondenceAddress.city}}
                </p>
              </v-col>
              <v-col>
                <p>
                  <v-icon small>mdi-account-outline</v-icon>
                  {{credit.clientData.pesel}}
                </p>
                <p v-if="credit.clientData.email">
                  <v-icon small>mdi-email-outline</v-icon>
                  {{credit.clientData.email}}
                </p>
                <p>
                  <v-icon small>mdi-phone-outline</v-icon>
                  {{credit.clientData.cellphone}}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <template v-if="credit.loanItem.insuranceType === 'VEHICLE'">
            <v-card-subtitle class="text-body-1">Pojazd:</v-card-subtitle>
            <v-card-text class="text--primary">
              <v-row>
                <v-col>
                  <p>
                    <v-icon small>mdi-car</v-icon>
                    nr rej. {{credit.loanItem.vehicle.licensePlate}}
                  </p>
                </v-col>
                <v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <template v-else>
            <v-card-subtitle class="text-body-1">Nieruchomość:</v-card-subtitle>
            <v-card-text class="text--primary">
              <v-row>
                <v-col>
                  <p>
                    <v-icon small>mdi-home-outline</v-icon>
                    {{getPropertyType(credit.loanItem.property.propertyType)}}
                  </p>
                </v-col>
                <v-col>
                  <p>Adres:<br>
                    ul. {{credit.loanItem.property.street}} {{credit.loanItem.property.house ?
                    credit.loanItem.property.house : ""}}
                    <template v-if="credit.loanItem.property.apartment">m.</template>
                    {{credit.loanItem.property.apartment ? credit.loanItem.property.apartment : ""}}
                    <br>
                    {{credit.loanItem.property.zipCode}} {{credit.loanItem.property.city}}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <credit-data-wng v-if="bankCode==='WNG'"/>
    <credit-data-drr v-if="bankCode==='DRR'"/>
    <v-row>
      <v-spacer/>
      <v-col cols="6" class="">
        <v-card>
          <v-card-text class="text-center">
            <v-row>
              <v-col>
                <v-checkbox label="Potwierdzam kompletność, aktualność i prawdziwość danych klienta"
                            v-model="confirmation"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="6">
        <v-btn color="primary" @click="next" x-large>Dalej
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
    <consent-modal/>
  </v-container>
</template>

<script>
  import ConsentModal from "@/components/modals/ConsentModal";
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import { propertyTypeOptions } from "@/constants";
  import CreditDataWng from "@/views/Credit/CreditDataWng";
  import CreditDataDrr from "@/views/Credit/CreditDataDrr";

  export default {
    name: "CreditData",
    components: { CreditDataDrr, CreditDataWng, ConsentModal, },
    computed: {
      credit() {
        return this.$store.state.credit
      },
      confirmation: {
        get() {
          return this.$store.state.credit.confirmation;
        },
        set(val) {
          this.$store.state.credit.confirmation = val;
        }
      },
      ...mapState({
        bankCode: state => state.bankCode
      })
    },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT
      }),
      getPropertyType(type) {
        return propertyTypeOptions.find(({ value }) => value === type).text;
      }
    },
  }
</script>

<style scoped>

</style>
