import { HTTP } from '@/services/http-common'
import router from "@/router";
import * as types from './actionTypes/authActionTypes'
import * as mutations from "@/store/mutations/mutation-types";

const authActions = {
  [types.FORGOT_PASSWORD]({ commit, state, dispatch }, { email }) {
    HTTP.post('/api/auth/forgotPassword', { email })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        router.push('/recoveryConfirmation').catch()
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL)
      })
  },
  [types.SIGN_UP]({ commit, state, dispatch }, { token, password }) {
    HTTP.post('/api/auth/signUp', { token, password })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          router.push('/confirmedRegistration').catch()
        } else {
          if (response.data.errCode === "ER_EXPIRED_ACTIVATION") {
            router.push({ name: "SignUpFailure" }).catch()
          }
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL)
      })
  },
  [types.PASSWORD_RECOVERY]({ commit, state, dispatch }, { token, password }) {
    HTTP.post('/api/auth/passwordRecovery', { token, password })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          router.push('/confirmedRegistration').catch()
        } else {
          if (response.data.errCode === "ER_EXPIRED_RECOVERY") {
            router.push({ name: "RecoveryFailure" }).catch()
          }
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL)
      })
  },
  [types.GET_EMAIL_FOR_TOKEN_SIGNUP]({ commit, state, dispatch }, token) {
    HTTP.post('/api/auth/emailSignUp', { token })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          commit(mutations.SET_USER_EMAIL, response.data.email)
        } else {
          commit(mutations.SHOW_ERROR_MODAL)
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL)
      })
  },
  [types.GET_EMAIL_FOR_TOKEN_RECOVERY]({ commit, state, dispatch }, token) {
    HTTP.post('/api/auth/emailForgotPassword', { token })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          commit(mutations.SET_USER_EMAIL, response.data.email)
        } else {
          commit(mutations.SHOW_ERROR_MODAL)
        }
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL)
      })
  },
  [types.LOGOUT]({ commit }) {
    HTTP.post('/api/logout')
      .then(response => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.CLEAR_CREDIT_DATA);
        commit(mutations.HIDE_COMPANY_DETAILS);
        sessionStorage.clear();
        router.push("/login").catch(() => {
        })
      })
      .catch(e => {
        commit(mutations.HIDE_LOADER);
        commit(mutations.CLEAR_CREDIT_DATA);
        commit(mutations.HIDE_COMPANY_DETAILS);
        sessionStorage.clear();
        router.push("/login").catch(() => {
        })
      })
  },
  [types.LOGIN]({ commit, dispatch, state }, credentials) {
    if (process.env.NODE_ENV === "development") {
      sessionStorage.loggedIn = "true";
      //sessionStorage.admin = "true";
      //router.push("/admin").catch();
      state.user.notification = true;
      router.push("/").catch()
    } else {
      HTTP.post('/api/login', credentials)
        .then(({ data }) => {
          if (data.responseStatus === "ERROR" && data.errCode === "ER_BAD_PASSWORD") {
            state.loginError.password = "Niepoprawne hasło";
            commit(mutations.HIDE_LOADER);
          }
          if (data.responseStatus === "ERROR" && data.errCode === "ER_USER_DISABLED") {
            commit(mutations.HIDE_LOADER);
            commit(mutations.SHOW_DISABLED_USER_MODAL)
          }
          if (data.responseStatus === "OK") {
            state.user = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              cellphone: data.cellphone,
              pesel: data.pesel,
              emailRequired: data.emailRequired,
              notification: data.notification
            };
            commit(mutations.HIDE_LOADER);
            sessionStorage.loggedIn = "true";
            if (data.role === "ADMIN" || data.role === "ROLE_ADMIN") {
              sessionStorage.admin = "true";
              router.push("/admin").catch()
            } else {
              router.push("/").catch()
            }
          }
        })
        .catch(e => {
          commit(mutations.HIDE_LOADER);
          commit(mutations.SHOW_ERROR_MODAL, e.message === "Network Error" ? ["Problem z połączeniem internetowym."] : [])
        });
    }
  }
};

export default authActions;
