<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Zmiana hasła
      </v-card-title>
      <v-card-subtitle>
        Podaj hasło aby zatwierdzić zmianę.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-spacer/>
          <v-col cols="10">
            <v-text-field outlined
                          ref="pass"
                          label="Stare hasło"
                          type="password"
                          v-model="password"
                          :error-messages="error"/>
            <v-text-field outlined
                          ref="pass1"
                          label="Nowe hasło"
                          v-model="password1"
                          :error-messages="error1"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          @click:append="show1 = !show1"/>
            <v-text-field outlined
                          ref="pass2"
                          label="Powtórz hasło"
                          v-model="password2"
                          type="password"
                          :error-messages="error2"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          @click:append="show2 = !show2"/>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialog = false">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               @click="setPassword">
          Zatwierdź
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "PasswordChange",
    data() {
      return {
        password: null,
        password1: null,
        password2: null,
        error: null,
        error1: null,
        error2: null,
        show1: false,
        show2: false,
      }
    },
    computed: {
      dialog: {
        get() {
          return this.$store.state.passwordModal
        },
        set(val) {
          this.$store.state.passwordModal = val
        }
      }
    },
    methods: {
      setPassword() {
        this.error = null;
        this.error1 = null;
        this.error2 = null;
        if(!this.password){
          this.error = "Nie podano hasła";
          this.$refs.pass.focus();
          return
        }
        if (!this.password1) {
          this.error1 = "Nie podano hasła";
          this.$refs.pass1.focus();
          return
        }
        if (!this.password2) {
          this.error2 = "Nie potwierdzono hasła";
          this.$refs.pass2.focus();
          return
        }
        if(this.password !== "hipro"){
          this.error = "Błędne hasło";
          this.$refs.pass.focus();
          return
        }
        if (this.password1 === this.password2) {
          this.dialog = false;
        } else {
          this.error2 = "Hasła nie są takie same";
          this.$refs.pass2.focus();
        }
      },
    }
  }
</script>

<style scoped>

</style>
