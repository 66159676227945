<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title class="headline">
        Sesja zakończona
      </v-card-title>
      <v-card-text>
        Ze względów bezpieczeństwa Twoje konto zostało wylogowane.<br>
        Aby korzystać z aplikacji zaloguj się ponownie.
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="closeModal">
          Przejdź do logowania
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "SessionEndModal",
    computed: {
      dialog: {
        get() {
          return this.$store.state.showSessionEndModal
        },
        set(val) {
          this.$store.state.showSessionEndModal = val
        }
      },
    },
    methods: {
      closeModal() {
        this.dialog = false;
        sessionStorage.clear();
        this.$router.push("/login").catch(()=>{})
      }
    }
  }
</script>

<style scoped>

</style>
