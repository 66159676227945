export default function validatePesel(value) {
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  let controlNumber = parseInt(value.substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
    sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
}
