<template>
  <v-stepper alt-labels v-model="currentStep">
    <v-stepper-header>
      <template v-for="(step, index) in displaySteps">
        <v-stepper-step :step="index+1"
                        :complete="currentStep>index+1">
          {{step.stepName}}
        </v-stepper-step>
        <v-divider v-if="displaySteps.length !== index +1"/>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
  import { stepperSteps } from "@/constants/creditProcessStages";

  export default {
    name: "Stepper",
    computed: {
      steps() {
        return stepperSteps;
      },
      displaySteps() {
        return stepperSteps.filter(({ display }) => display);
      },
      currentStep() {
        return this.steps.findIndex(({ step }) => step === this.$store.state.currentStep) + 1
      }
    }
  }
</script>

<style>
  .v-stepper__step {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .v-divider {
    margin-top: 12px !important;
  }

  .v-stepper {
    box-shadow: none !important;
  }

  .v-stepper__label {
    text-align: center !important;
    font-size: 12px;
  }

</style>
