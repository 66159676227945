export const GET_DOCUMENT = "getDocumentApi";
export const INIT_APPLICATION = "initApplicationApi";
export const BEGIN_APPLICATION = "beginApplicationApi";
export const CONFIRM_APPLICATION = "confirmApplicationApi";
export const CONFIRM_CONDITIONS = "confirmConditionsApi";
export const CONFIRM_CONTRACT = "confirmContractApi";
export const CONFIRM_CONTRACT_INNER = "confirmContractInner";
export const REQUEST_NEW_PIN = "requestNewPinApi";
export const PREPARE_FINALIZE = "prepareFinalizeApi";
export const GET_FINAL_DOCUMENTS = "getFinalDocumentsApi";
export const WAIT_FOR_DECISION = "waitForDecisionApi";
export const WAIT_FOR_CONTRACT = "waitForContractApi";
export const CANCEL_APPLICATION = "cancelApplicationApi";
export const SAVE_APPLICATION = "saveApplicationApi";
export const LOAD_APPLICATION = "loadApplicationApi";
export const HIDE_APPLICATION = 'hideApplicationApi';
export const SHOW_APPLICATION = 'showApplicationApi';
export const DRAW_BANK = "drawBankApi";
export const FINALIZE = "finalizeApi";
export const GET_APPLICATIONS = "getApplicationsApi";
export const FIND_COMPANY = "findCompanyApi";
export const GET_PROCESS_OPTIONS = "getProcessOptionsApi";
