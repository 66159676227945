import {
  validateApartment,
  validateCity,
  validateCreditAmount,
  validateEmail,
  validateFirstName,
  validateHouse,
  validateLastName,
  validateLength100,
  validateLength20,
  validateLength25,
  validateLicensePlate, validateMaxAmount, validateMinAmount,
  validatePesel,
  validatePhoneNumber,
  validateStreet,
  validateZipCode
} from "../fieldValidators"
import { validateLength50 } from "@/validators/fieldValidators";

export default function validateClientData(value, emailRequired) {
  const errorList = [];

  const { clientData, loanItem, insurer } = value;

  if (!clientData.firstName || !validateFirstName.validate(clientData.firstName) || !validateLength25.validate(clientData.firstName)) {
    errorList.push('firstName');
  }

  if (!clientData.lastName || !validateLastName.validate(clientData.lastName) || !validateLength50.validate(clientData.lastName)) {
    errorList.push('lastName');
  }

  if (!clientData.pesel || !validatePesel.validate(clientData.pesel)) {
    errorList.push('pesel');
  }

  if (!clientData.cellphone || !validatePhoneNumber.validate(clientData.cellphone)) {
    errorList.push('cellphone');
  }

  if (!clientData.email || !validateEmail.validate(clientData.email) || !validateLength100.validate(clientData.email)) {
    errorList.push('email');
  }

  const { mainAddress, correspondenceAddress } = clientData;

  if (!mainAddress.city || !validateCity.validate(mainAddress.city) || !validateLength50.validate(mainAddress.city)) {
    errorList.push('mainCity');
  }

  if (mainAddress.street) {
    if (!validateStreet.validate(mainAddress.street) || !validateLength50.validate(mainAddress.street)) {
      errorList.push('mainStreet');
    }
  }

  if (!mainAddress.house || !validateHouse.validate(mainAddress.house) || !validateLength25.validate(mainAddress.house)) {
    errorList.push('mainHouse');
  }

  if (mainAddress.apartment) {
    if (!validateApartment.validate(mainAddress.apartment) || !validateLength25.validate(mainAddress.apartment)) {
      errorList.push('mainApartment');
    }
  }

  if (!mainAddress.zipCode || !validateZipCode.validate(mainAddress.zipCode)) {
    errorList.push('mainZipCode');
  }

  if (clientData.differentCorrespondence) {
    if (!correspondenceAddress.city || !validateCity.validate(correspondenceAddress.city) || !validateLength50.validate(correspondenceAddress.city)) {
      errorList.push('correspondenceCity');
    }

    if (correspondenceAddress.street) {
      if (!validateStreet.validate(correspondenceAddress.street) || !validateLength50.validate(correspondenceAddress.street)) {
        errorList.push('correspondenceStreet');
      }
    }

    if (!correspondenceAddress.house || !validateHouse.validate(correspondenceAddress.house) || !validateLength25.validate(correspondenceAddress.house)) {
      errorList.push('correspondenceHouse');
    }

    if (correspondenceAddress.apartment) {
      if (!validateApartment.validate(correspondenceAddress.apartment) || !validateLength25.validate(correspondenceAddress.apartment)) {
        errorList.push('correspondenceApartment');
      }
    }

    if (!correspondenceAddress.zipCode || !validateZipCode.validate(correspondenceAddress.zipCode)) {
      errorList.push('correspondenceZipCode');
    }
  }

  if (!loanItem.amount || !validateCreditAmount.validate(loanItem.amount)) {
    errorList.push('amount');
  } else if (!validateMinAmount.validate(loanItem.amount)){
    errorList.push('lowAmount');
  } else if (!validateMaxAmount.validate(loanItem.amount)) {
    errorList.push('highAmount');
  }

  if(!insurer) {
    errorList.push('insurer');
  }

  if (loanItem.insuranceType === "VEHICLE") {
    if (!loanItem.vehicle.make || !validateLength25.validate(loanItem.vehicle.make)) {
      errorList.push('make');
    }

    if (!loanItem.vehicle.model || !validateLength100.validate(loanItem.vehicle.model)) {
      errorList.push('model');
    }

    if (!loanItem.vehicle.licensePlate || !validateLicensePlate.validate(loanItem.vehicle.licensePlate) || !validateLength20.validate(loanItem.vehicle.licensePlate)) {
      errorList.push('licensePlate');
    }
  }

  if (loanItem.insuranceType === "PROPERTY") {
    if (!loanItem.property.city || !validateCity.validate(loanItem.property.city) || !validateLength50.validate(loanItem.property.city)) {
      errorList.push('propertyCity');
    }

    if (loanItem.property.street) {
      if (!validateStreet.validate(loanItem.property.street) || !validateLength50.validate(loanItem.property.street)) {
        errorList.push('propertyStreet');
      }
    }

    if (!loanItem.property.house || !validateHouse.validate(loanItem.property.house) || !validateLength25.validate(loanItem.property.house)) {
      errorList.push('propertyHouse');
    }

    if (loanItem.property.apartment) {
      if (!validateApartment.validate(loanItem.property.apartment) || !validateLength25.validate(loanItem.property.apartment)) {
        errorList.push('propertyApartment');
      }
    }

    if (!loanItem.property.zipCode || !validateZipCode.validate(loanItem.property.zipCode)) {
      errorList.push('propertyZipCode');
    }
  }

  return errorList;
}
