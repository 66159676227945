<template>
  <v-card>
    <v-card-title>
      Oblicz orientacyjną wyskość raty
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="auto" lg="4">
          <v-text-field label="Wysokość składki" outlined v-model="amount" :error-messages="error">
            <span slot="append" class="text-body-2">zł</span>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" x-large style="display: inline-block"
                 @click="calculate">
            Oblicz
          </v-btn>
        </v-col>
        <v-col  class="text-center" v-if="calculated">
          <v-row>
            <v-col cols="auto" lg="4" />
            <v-col cols="auto" lg="4" v-if="partner!=='UNI'">
              <img src="@/assets/wonga.svg"
                   alt="Wonga Logo"
                   height="30"
                   style="vertical-align: middle;"/>
            </v-col>
            <v-col cols="auto" lg="4">
              <img src="@/assets/drobnaratka.png"
                   alt="DrobnaRatka Logo"
                   height="30"
                   style="vertical-align: middle;"/>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="mt-4 text-right" cols="auto" lg="4">
              <span class="text-h5">miesięcznie: </span>
            </v-col>
            <v-col cols="auto" lg="4" v-if="partner!=='UNI'">
              <span class="text-h3">{{installmentWonga}} zł</span>
            </v-col>
            <v-col cols="auto" lg="4">
              <span class="text-h3">{{installmentDrr}} zł</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="text-right" cols="auto" lg="4">
              <span>w sumie:</span>
            </v-col>
            <v-col cols="auto" lg="4" v-if="partner!=='UNI'">
              <span>10 rat x {{installmentWonga}} zł = <span class="bold-text">{{totalWonga}} zł</span></span>
            </v-col>
            <v-col cols="auto" lg="4">
              <span>12 rat x {{installmentDrr}} zł = <span class="bold-text">{{totalDrr}} zł</span></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "InstallmentCalculator",
    data() {
      return {
        installmentWonga: 0,
        installmentDrr: 0,
        totalWonga: 0,
        totalDrr: 0,
        calculated: false,
        amount: null,
        error: null
      }
    },
    computed: mapState(['partner']),
    methods: {
      calculate() {
        const mirWonga = 2.3;
        const mirDrr = 1.9;
        if (this.amount) {
          this.amount = this.amount.replace(",", ".");
          const amount = Number(this.amount);
          if (!isNaN(amount)) {
            if (this.amount < 500) {
              this.error = "Podana kwota jest za niska."
            } else if (this.amount > 8484) {
              this.error = "Podana kwota jest za wysoka."
            } else {
              this.installmentWonga = (amount * (1 + mirWonga / 100 * 10) / 10).toFixed(0);
              this.installmentDrr = (amount * (1 + mirDrr / 100 * 12) / 12).toFixed(0);
              this.totalWonga = this.installmentWonga * 10;
              this.totalDrr = this.installmentDrr * 12;
              this.$store.state.credit.loanItem.amount = this.amount;
              this.calculated = true;
            }
          } else {
            this.error = "Podaj poprawną kwotę."
          }
        } else {
          this.error = "Podaj kwotę."
        }
      }
    }
  }
</script>

<style scoped>
  .bold-text {
    font-weight: 600;
  }
</style>
