<template>
  <div>
    <v-checkbox color="primary" class="mt-2 mb-2"
                :input-value="groupValue"
                :indeterminate="isIndeterminate"
                @change="groupChange"
                messages="1">
      <template v-slot:label>
        <span class="reqStar" v-bind:class="group.mandatory ? 'required' : ''"/>
        <consent :consent="group" class="ml-2"/>
      </template>
      <template v-slot:message>
        <div v-if="isExpanded" class="expand" @click="collapse">Zwiń...</div>
        <div v-else class="expand" @click="expand">Rozwiń zgody, odczytaj ich treść i odznacz pojedynczo, jeśli klient chce zapoznać się z ich treścią i
          zaakceptować każdą ze zgód oddzielnie</div>
      </template>
    </v-checkbox>
    <div class="ml-9" v-if="isExpanded">
      <template v-for="(consent, index) in groupConsents">
        <v-checkbox color="primary" class="mt-2 mb-n2" v-model="consentList[consentIndex(consent.code)].value">
          <template v-slot:label>
            <span class="reqStar" v-bind:class="consent.mandatory ? 'required' : ''"/>
            <consent :consent="consent" class="ml-2"/>
          </template>
        </v-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Consent from "@/components/modals/Consent";

  export default {
    name: "ConsentGroup",
    components: { Consent },
    data() {
      return {
        isExpanded: false
      }
    },
    computed: {
      ...mapState([
        'consents',
        'consentList'
      ]),
      groupConsents() {
        return this.consents.filter(({ code }) => this.group.consentCodeList.includes(code))
      },
      groupValue() {
        const groupConsents = this.consentList.filter(({ code }) => this.group.consentCodeList.includes(code));
        return groupConsents.every(({ value }) => value);
      },
      isIndeterminate() {
        const groupConsents = this.consentList.filter(({ code }) => this.group.consentCodeList.includes(code));
        return (groupConsents.some(({ value }) => value) && groupConsents.some(({ value }) => !value));
      }
    },
    methods: {
      consentIndex(consentCode) {
        return this.consentList.findIndex(({ code }) => consentCode === code)
      },
      groupChange() {
        if (this.isIndeterminate || this.groupValue === false) {
          this.setConsents(true)
        } else {
          this.setConsents(false)
        }
      },
      setConsents(value) {
        this.groupConsents.forEach(({ code }) => {
          this.consentList[this.consentIndex(code)].value = value
        })
      },
      expand() {
        this.isExpanded = true;
      },
      collapse() {
        this.isExpanded = false;
      }
    },
    props: {
      group: {
        required: true
      },
      index: {
        required: true
      }
    }
  }
</script>

<style scoped>
  .expand {
    text-decoration: underline;
    /*text-align: right;*/
    color: #f20316;
    cursor: pointer;
    font-size: 11px;
    margin-left: 40px;
    /*width: 60%;*/
  }

  .required::after {
    content: "*";
    color: #ff0000;
    font-size: 16px;
    margin-left: -4px;
    margin-right: -2px;
  }
</style>
