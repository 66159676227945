<template>
  <v-dialog v-model="dialog"
            persistent
            width="300">
    <v-card color="primary"
            dark>
      <v-card-text>
        {{message ? message : "Proszę czekać..."}}
        <v-progress-linear indeterminate
                           color="white"
                           class="mb-0"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "Loader",
    computed: {
      dialog() {
        return this.$store.state.loading.show
      },
      message() {
        return this.$store.state.loading.message
      },
    }
  }
</script>

<style scoped>

</style>
