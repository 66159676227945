export default () => {
  return {
    pinApp: "",
    pinCon: "",
    confirmation: false,
    processId: null,
    policyId: null,
    accountNumber: null,
    insurer: null,
    isBusinessAvailable: false,
    clientType: "PRIVATE",
    companyData: {
      name: null,
      nip: null,
      city: null,
      street: null,
      house: null,
      apartment: null,
      zipCode: null,
    },
    clientData: {
      firstName: null,
      lastName: null,
      pesel: null,
      cellphone: null,
      email: null,
      differentCorrespondence: false,
      birthCountry: "PL",
      mainAddress: {
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      },
      correspondenceAddress: {
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      },
      identityDocumentData: {
        id: "",
        expiryDate: null,
        issueDate: null,
        indefinite: false
      },
      monthlyIncome: null
    },
    loanItem: {
      insuranceType: "VEHICLE",
      amount: null,
      vehicle: {
        make: null,
        model: null,
        licensePlate: null
      },
      property: {
        propertyType: "FLAT",
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      }
    }
  }
};
