<template>
  <div>
    <div class="text-h6">Rejestracja nie powiodła się</div>
    <p>Twój link aktywacyjny wygasł. Skontaktuj się z <a class="no-style-link" href="mailto:wsparcie@hipro.pl">wsparcie@hipro.pl</a>
      i poproś o nowy link do aktywacji konta.</p>
  </div>
</template>

<script>
  export default {
    name: "SignUpFailure",
  }
</script>

<style scoped>

</style>
