<template>
  <v-container class="mb-8">
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <v-row v-if="isBusinessAvailable" class="mt-4">
          <v-col>
            <v-btn-toggle v-model="clientType"
                          active-class="chosen-type"
                          color="primary"
                          style="width: 100%"
                          mandatory>
              <v-btn class="client-type">
                Osoba fizyczna
              </v-btn>
              <v-btn class="client-type">
                Firma (JDG)
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="clientType===1">
          <v-col>
            <div class="text-h6 mb-2">Dane działalności gospodarczej</div>
            <v-row>
              <v-col>
                <v-text-field v-model="nip"
                              label="NIP"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('nip')"
                              name="nip"
                              v-validate="'required||nip'"
                />
              </v-col>
              <v-col>
                <v-btn x-large color="primary" @click="findCompany">Pobierz dane</v-btn>
              </v-col>
            </v-row>
            <template v-if="showCompanyDetails">
              <v-text-field v-model="companyName"
                            label="Nazwa"
                            outlined
                            autocomplete="off"
                            :disabled="!enableCompanyDetails"
                            :error-messages="errors.first('companyName')"
                            name="companyName"
                            v-validate="'required'"
              />
              <v-text-field v-model="companyStreet"
                            label="Ulica"
                            type="text"
                            outlined
                            :disabled="!enableCompanyDetails"
                            autocomplete="off"
                            :error-messages="errors.first('streetCompany')"
                            name="streetCompany"
                            v-validate="'street||validate50'"/>
              <v-row class="mb-n10">
                <v-col>
                  <v-text-field v-model="companyHouse"
                                label="Numer domu"
                                type="text"
                                outlined
                                :disabled="!enableCompanyDetails"
                                autocomplete="off"
                                :error-messages="errors.first('houseCompany')"
                                name="houseCompany"
                                v-validate="'required||house||validate25'"/>
                </v-col>
                <v-col>
                  <v-text-field v-model="companyApartment"
                                label="Numer lokalu"
                                type="text"
                                outlined
                                :disabled="!enableCompanyDetails"
                                autocomplete="off"
                                :error-messages="errors.first('apartmentCompany')"
                                name="apartmentCompany"
                                v-validate="'apartment||validate25'"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="companyZipCode"
                                label="Kod pocztowy"
                                type="text"
                                outlined
                                :disabled="!enableCompanyDetails"
                                autocomplete="off"
                                :error-messages="errors.first('zipCodeCompany')"
                                name="zipCodeCompany"
                                v-validate="'required||zipCode'"/>
                </v-col>
                <v-col/>
              </v-row>
              <v-text-field v-model="companyCity"
                            label="Miasto"
                            type="text"
                            outlined
                            :disabled="!enableCompanyDetails"
                            autocomplete="off"
                            :error-messages="errors.first('cityCompany')"
                            name="cityCompany"
                            v-validate="'required||city||validate50'"/>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <div class="text-h4 mb-8 mt-4">Dane klienta</div>
        <v-text-field v-model="firstName"
                      label="Imię"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('firstName')"
                      name="firstName"
                      v-validate="'required||firstName||validate25'"/>
        <v-text-field v-model="lastName"
                      label="Nazwisko"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('lastName')"
                      name="lastName"
                      v-validate="'required||lastName||validate50'"/>
        <v-text-field v-model="pesel"
                      label="Numer Pesel"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('pesel')"
                      name="pesel"
                      v-validate="'required||pesel'"/>
        <v-text-field v-model="cellphone"
                      label="Nr tel. komórkowego"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('cellphone')"
                      name="cellphone"
                      v-validate="'required||cellphone'"/>
        <v-text-field v-model="email"
                      label="Adres email"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('email')"
                      name="email"
                      v-validate="'required|email|validate100'"/>
        <div class="text-h6 mb-2">Adres</div>

        <v-text-field v-model="streetMain"
                      label="Ulica"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('streetMain')"
                      name="streetMain"
                      v-validate="'street||validate50'"/>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field v-model="houseMain"
                          label="Numer domu"
                          type="text"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('houseMain')"
                          name="houseMain"
                          v-validate="'required||house||validate25'"/>
          </v-col>
          <v-col>
            <v-text-field v-model="apartmentMain"
                          label="Numer mieszkania"
                          type="text"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('apartmentMain')"
                          name="apartmentMain"
                          v-validate="'apartment||validate25'"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="zipCodeMain"
                          label="Kod pocztowy"
                          type="text"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('zipCodeMain')"
                          name="zipCodeMain"
                          v-validate="'required||zipCode'"/>
          </v-col>
          <v-col/>
        </v-row>
        <v-text-field v-model="cityMain"
                      label="Miasto"
                      type="text"
                      outlined
                      autocomplete="off"
                      :error-messages="errors.first('city')"
                      name="city"
                      v-validate="'required||city||validate50'"/>
        <v-switch
          v-model="differentCorrespondence"
          label="Inny adres korespondencyjny"
          color="primary">

        </v-switch>
        <template v-if="differentCorrespondence">
          <v-text-field v-model="streetCorrespondence"
                        label="Ulica"
                        type="text"
                        outlined
                        autocomplete="off"
                        :error-messages="errors.first('streetCorrespondence')"
                        name="streetCorrespondence"
                        v-validate="'street||validate50'"/>
          <v-row class="mb-n10">
            <v-col>
              <v-text-field v-model="houseCorrespondence"
                            label="Numer domu"
                            type="text"
                            outlined
                            autocomplete="off"
                            :error-messages="errors.first('houseCorrespondence')"
                            name="houseCorrespondence"
                            v-validate="'required||house||validate25'"/>
            </v-col>
            <v-col>
              <v-text-field v-model="apartmentCorrespondence"
                            label="Numer mieszkania"
                            type="text"
                            outlined
                            autocomplete="off"
                            :error-messages="errors.first('apartmentCorrespondence')"
                            name="apartmentCorrespondence"
                            v-validate="'apartment||validate25'"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="zipCodeCorrespondence"
                            label="Kod pocztowy"
                            type="text"
                            outlined
                            autocomplete="off"
                            :error-messages="errors.first('zipCodeCorrespondence')"
                            name="zipCodeCorrespondence"
                            v-validate="'required||zipCode'"/>
            </v-col>
            <v-col/>
          </v-row>
          <v-text-field v-model="cityCorrespondence"
                        label="Miasto"
                        type="text"
                        outlined
                        autocomplete="off"
                        :error-messages="errors.first('cityCorrespondence')"
                        name="cityCorrespondence"
                        v-validate="'required||city||validate50'"/>
        </template>

      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="4">
        <div class="text-h4 mb-8">Przedmiot ubezpieczenia</div>
        <v-row>
          <v-col>
            <v-btn-toggle v-model="insuranceType"
                          active-class="chosen-type"
                          color="white"
                          style="width: 100%"
                          borderless
                          mandatory>
              <v-btn class="insurance-type">
                <v-icon class="insurance-type-icon">mdi-car-outline</v-icon>
              </v-btn>
              <v-btn class="insurance-type">
                <v-icon class="insurance-type-icon">mdi-home-outline</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox :items="insurerList"
                        label="Nazwa ubezpieczyciela"
                        v-model="insurer"
                        :error-messages="errors.first('insurer')"
                        name="insurer"
                        v-validate="'required'"
                        outlined/>
          </v-col>
        </v-row>
        <v-row v-if="insuranceType===0">
          <v-col>
            <v-text-field v-model="vehicleAmount"
                          label="Wysokość składki"
                          type="number"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('amountVehicle')"
                          name="amountVehicle"
                          v-validate="'required||amount||minAmount||maxAmount'">
              <span slot="append" class="text-body-2">zł</span>
            </v-text-field>
            <v-text-field v-model="licensePlate"
                          label="Numer rejestracyjny"
                          type="text"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('licensePlate')"
                          name="licensePlate"
                          v-validate="'required||licensePlate||validate20'"/>
            <v-combobox v-model="make"
                        label="Marka"
                        outlined
                        :items="makeItems"
                        autocomplete="off"
                        :error-messages="errors.first('make')"
                        name="make"
                        v-validate="'required'"/>
            <v-text-field v-model="model"
                          label="Model"
                          type="text"
                          outlined
                          autocomplete="off"
                          :error-messages="errors.first('model')"
                          name="model"
                          v-validate="'required||validate100'"/>
          </v-col>
        </v-row>
        <v-row v-else-if="insuranceType===1">
          <v-col>
            <v-row>
              <v-col>
                <v-text-field v-model="propertyAmount"
                              label="Wysokość składki"
                              type="number"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('amountProperty')"
                              name="amountProperty"
                              v-validate="'required||amount||minAmount||maxAmount'">
                  <span slot="append" class="text-body-2">zł</span>
                </v-text-field>
              </v-col>
            </v-row>
            <v-select outlined
                      autocomplete="off" v-model="propertyType"
                      label="Typ nieruchomości"
                      :items="propertyItems"
                      item-text="text"
                      item-value="value"/>
            <div class="text-h6 mb-2">
              Adres nieruchomości
              <v-btn color="primary"
                     @click="copyAddress"
                     x-small
                     style="float: right; position: relative; top: 6px">
                Kopiuj adres klienta
              </v-btn>
            </div>
            <v-row class="mb-n10">
              <v-col>
                <v-text-field v-model="propertyStreet"
                              label="Ulica"
                              type="text"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('propertyStreet')"
                              name="propertyStreet"
                              v-validate="'street||validate50'"/>
              </v-col>
            </v-row>
            <v-row class="mb-n10">
              <v-col>
                <v-text-field v-model="propertyHouse"
                              label="Numer domu"
                              type="text"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('propertyHouse')"
                              name="propertyHouse"
                              v-validate="'required||house||validate25'"/>
              </v-col>
              <v-col>
                <v-text-field v-model="propertyApartment"
                              label="Numer mieszkania"
                              type="text"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('propertyApartment')"
                              name="propertyApartment"
                              v-validate="'apartment||validate25'"/>
              </v-col>
            </v-row>
            <v-row class="mb-n10">
              <v-col>
                <v-text-field v-model="propertyZipCode"
                              label="Kod pocztowy"
                              type="text"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('propertyZipCode')"
                              name="propertyZipCode"
                              v-validate="'required||zipCode'"/>
              </v-col>
              <v-spacer/>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="propertyCity"
                              label="Miasto"
                              type="text"
                              outlined
                              autocomplete="off"
                              :error-messages="errors.first('propertyCity')"
                              name="propertyCity"
                              v-validate="'required||city||validate50'"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="saveApplication" x-large>Zapisz wniosek</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="next" x-large>Dalej
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
  import * as actions from "@/store/actions/action-types";
  import { mapActions } from "vuex";
  import { carMakeOptions, insurerOptions, propertyTypeOptions } from '@/constants'
  import { EventBus } from '@/components/event-bus'
  import {
    validateApartment,
    validateCity,
    validateCreditAmount,
    validateEmail,
    validateFirstName,
    validateHouse,
    validateLastName,
    validateLength100,
    validateLength20,
    validateLength25,
    validateLength50,
    validateLicensePlate, validateMake,
    validateMaxAmount,
    validateMinAmount, validateModel,
    validateNipField,
    validatePesel,
    validatePhoneNumber,
    validateStreet,
    validateZipCode
  } from "@/validators/fieldValidators";

  export default {
    name: "ClientData",
    created() {
      this.$validator.extend('firstName', validateFirstName);
      this.$validator.extend('lastName', validateLastName);
      this.$validator.extend('pesel', validatePesel);
      this.$validator.extend('cellphone', validatePhoneNumber);
      this.$validator.extend('email', validateEmail);
      this.$validator.extend('city', validateCity);
      this.$validator.extend('street', validateStreet);
      this.$validator.extend('house', validateHouse);
      this.$validator.extend('apartment', validateApartment);
      this.$validator.extend('zipCode', validateZipCode);
      this.$validator.extend('amount', validateCreditAmount);
      this.$validator.extend('validate20', validateLength20);
      this.$validator.extend('validate25', validateLength25);
      this.$validator.extend('validate50', validateLength50);
      this.$validator.extend('validate100', validateLength100);
      this.$validator.extend('make', validateMake);
      this.$validator.extend('model', validateModel);
      this.$validator.extend('licensePlate', validateLicensePlate);
      this.$validator.extend('nip', validateNipField);
      this.$validator.extend('minAmount', validateMinAmount);
      this.$validator.extend('maxAmount', validateMaxAmount);
      EventBus.$on("validate", () => {
        this.$validator.validateAll().then(() => {
            if (this.errors.items[0]) {
              const firstError = this.$el.querySelector('input[name="' + this.errors.items[0].field + '"]');
              firstError.scrollIntoView({ block: "center" });
              firstError.focus();
            }
          }
        )
      });
    },
    data() {
      return {
        propertyItems: propertyTypeOptions,
        makeItems: carMakeOptions
      }
    },
    computed: {
      insurer: {
        get() {
          return this.$store.state.credit.insurer;
        },
        set(val) {
          this.$store.state.credit.insurer = val;
        }
      },
      insurerList() {
        if (this.$store.state.credit.loanItem.insuranceType === "VEHICLE") {
          return insurerOptions.vehicle;
        } else {
          return insurerOptions.property
        }
      },
      firstName: {
        get() {
          return this.$store.state.credit.clientData.firstName;
        },
        set(val) {
          this.$store.state.credit.clientData.firstName = val.trim();
        }
      },
      lastName: {
        get() {
          return this.$store.state.credit.clientData.lastName;
        },
        set(val) {
          this.$store.state.credit.clientData.lastName = val;
        }
      },
      pesel: {
        get() {
          return this.$store.state.credit.clientData.pesel;
        },
        set(val) {
          this.$store.state.credit.clientData.pesel = val.trim();
        }
      },
      make: {
        get() {
          return this.$store.state.credit.loanItem.vehicle.make;
        },
        set(val) {
          this.$store.state.credit.loanItem.vehicle.make = val;
        }
      },
      model: {
        get() {
          return this.$store.state.credit.loanItem.vehicle.model;
        },
        set(val) {
          this.$store.state.credit.loanItem.vehicle.model = val;
        }
      },
      cellphone: {
        get() {
          return this.$store.state.credit.clientData.cellphone;
        },
        set(val) {
          this.$store.state.credit.clientData.cellphone = val;
        }
      },
      email: {
        get() {
          return this.$store.state.credit.clientData.email;
        },
        set(val) {
          this.$store.state.credit.clientData.email = val.trim();
        }
      },
      differentCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.differentCorrespondence;
        },
        set(val) {
          this.$store.state.credit.clientData.differentCorrespondence = val;
        }
      },
      cityMain: {
        get() {
          return this.$store.state.credit.clientData.mainAddress.city;
        },
        set(val) {
          this.$store.state.credit.clientData.mainAddress.city = val;
        }
      },
      streetMain: {
        get() {
          return this.$store.state.credit.clientData.mainAddress.street;
        },
        set(val) {
          this.$store.state.credit.clientData.mainAddress.street = val;
        }
      },
      houseMain: {
        get() {
          return this.$store.state.credit.clientData.mainAddress.house;
        },
        set(val) {
          this.$store.state.credit.clientData.mainAddress.house = val.trim();
        }
      },
      apartmentMain: {
        get() {
          return this.$store.state.credit.clientData.mainAddress.apartment;
        },
        set(val) {
          this.$store.state.credit.clientData.mainAddress.apartment = val.trim();
        }
      },
      zipCodeMain: {
        get() {
          return this.$store.state.credit.clientData.mainAddress.zipCode;
        },
        set(val) {
          this.$store.state.credit.clientData.mainAddress.zipCode = val;
        }
      },
      cityCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.correspondenceAddress.city;
        },
        set(val) {
          this.$store.state.credit.clientData.correspondenceAddress.city = val;
        }
      },
      streetCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.correspondenceAddress.street;
        },
        set(val) {
          this.$store.state.credit.clientData.correspondenceAddress.street = val;
        }
      },
      houseCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.correspondenceAddress.house;
        },
        set(val) {
          this.$store.state.credit.clientData.correspondenceAddress.house = val.trim();
        }
      },
      apartmentCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.correspondenceAddress.apartment;
        },
        set(val) {
          this.$store.state.credit.clientData.correspondenceAddress.apartment = val.trim();
        }
      },
      zipCodeCorrespondence: {
        get() {
          return this.$store.state.credit.clientData.correspondenceAddress.zipCode;
        },
        set(val) {
          this.$store.state.credit.clientData.correspondenceAddress.zipCode = val;
        }
      },
      insuranceType: {
        get() {
          return this.$store.state.credit.loanItem.insuranceType === "VEHICLE" ? 0 : 1;
        },
        set(val) {
          this.$store.state.credit.loanItem.insuranceType = val === 0 ? "VEHICLE" : "PROPERTY";
        }
      },
      clientType: {
        get() {
          return this.$store.state.credit.clientType === "PRIVATE" ? 0 : 1;
        },
        set(val) {
          this.$store.state.credit.clientType = val === 0 ? "PRIVATE" : "BUSINESS";
        }
      },
      nip: {
        get() {
          return this.$store.state.credit.companyData.nip;
        },
        set(val) {
          this.$store.state.credit.companyData.nip = val;
        }
      },
      companyName: {
        get() {
          return this.$store.state.credit.companyData.name;
        },
        set(val) {
          this.$store.state.credit.companyData.name = val;
        }
      },
      companyStreet: {
        get() {
          return this.$store.state.credit.companyData.street;
        },
        set(val) {
          this.$store.state.credit.companyData.street = val;
        }
      },
      companyCity: {
        get() {
          return this.$store.state.credit.companyData.city;
        },
        set(val) {
          this.$store.state.credit.companyData.city = val;
        }
      },
      companyHouse: {
        get() {
          return this.$store.state.credit.companyData.house;
        },
        set(val) {
          this.$store.state.credit.companyData.house = val.trim();
        }
      },
      companyApartment: {
        get() {
          return this.$store.state.credit.companyData.apartment;
        },
        set(val) {
          this.$store.state.credit.companyData.apartment = val.trim();
        }
      },
      companyZipCode: {
        get() {
          return this.$store.state.credit.companyData.zipCode;
        },
        set(val) {
          this.$store.state.credit.companyData.zipCode = val;
        }
      },
      vehicleAmount: {
        get() {
          return this.$store.state.credit.loanItem.amount;
        },
        set(val) {
          this.$store.state.credit.loanItem.amount = val;
        }
      },
      propertyAmount: {
        get() {
          return this.$store.state.credit.loanItem.amount;
        },
        set(val) {
          this.$store.state.credit.loanItem.amount = val;
        }
      },
      licensePlate: {
        get() {
          return this.$store.state.credit.loanItem.vehicle.licensePlate;
        },
        set(val) {
          this.$store.state.credit.loanItem.vehicle.licensePlate = val.toUpperCase();
        }
      },
      propertyType: {
        get() {
          return this.$store.state.credit.loanItem.property.propertyType;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.propertyType = val;
        }
      },
      propertyCity: {
        get() {
          return this.$store.state.credit.loanItem.property.city;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.city = val;
        }
      },
      propertyStreet: {
        get() {
          return this.$store.state.credit.loanItem.property.street;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.street = val;
        }
      },
      propertyHouse: {
        get() {
          return this.$store.state.credit.loanItem.property.house;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.house = val.trim();
        }
      },
      propertyApartment: {
        get() {
          return this.$store.state.credit.loanItem.property.apartment;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.apartment = val.trim();
        }
      },
      propertyZipCode: {
        get() {
          return this.$store.state.credit.loanItem.property.zipCode;
        },
        set(val) {
          this.$store.state.credit.loanItem.property.zipCode = val;
        }
      },
      showCompanyDetails() {
        return this.$store.state.showCompanyDetails;
      },
      enableCompanyDetails() {
        return this.$store.state.enableCompanyDetails;
      },
      isBusinessAvailable() {
        return this.$store.state.credit.isBusinessAvailable;
      },
      emailRequired() {
        return this.$store.state.user.emailRequired;
      }
    },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT,
        saveApplication: actions.SAVE_APPLICATION,
        findCompany: actions.FIND_COMPANY
      }),
      copyAddress() {
        this.propertyStreet = this.streetMain;
        this.propertyCity = this.cityMain;
        this.propertyHouse = this.houseMain;
        this.propertyApartment = this.apartmentMain;
        this.propertyZipCode = this.zipCodeMain;
      }
    }
  }
</script>

<style>
  .insurance-type {
    height: 100px !important;
    min-width: 130px !important;
    width: 50% !important;
    border: 2px solid #DBDEE6 !important;
    border-radius: 4px !important;
    background-color: white !important;
    font-size: 60px !important;
  }

  .client-type {
    min-width: 130px !important;
    height: 70px !important;
    width: 50% !important;
    border: 2px solid #DBDEE6 !important;
    border-radius: 4px !important;
    background-color: white !important;
  }

  .insurance-type-icon {
    color: #808CA6 !important;
    font-size: 60px !important;
  }

  .client-type.chosen-type {
    border: 2px solid #F20316 !important;
    background-color: #F20316 !important;
    color: white !important;
  }

  .unilink .client-type.chosen-type {
    border: 2px solid #FF9F38 !important;
    background-color: #FF9F38 !important;
    color: white !important;
  }

  .chosen-type .insurance-type-icon {
    color: #F20316 !important;
    font-size: 60px !important;
  }

  .unilink .chosen-type .insurance-type-icon {
    color: #FF9F38 !important;
    font-size: 60px !important;
  }
</style>
