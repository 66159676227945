<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        OK
      </v-card-title>
      <v-card-text>
        Wniosek został zapisany
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="okClose">
          Wróć na listę wniosków
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import router from "@/router";

  export default {
    name: "OkModal",
    computed: {
      dialog: {
        get() {
          return this.$store.state.showOkModal
        },
        set(val) {
          this.$store.state.showOkModal = val
        }
      },
    },
    methods: {
      okClose() {
        this.dialog = false;
        router.push({ name: "Applications" }).catch(() => {
        })
      }
    }
  }
</script>

<style scoped>

</style>
