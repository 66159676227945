import { validateIdentityDocumentNumber, validateMonthlyIncome } from "../fieldValidators"

export default function validateCreditData(value, bankCode) {
  const errorList = [];

  const { clientData } = value;

  if (bankCode === "WNG") {
    if (!clientData.identityDocumentData.id || !validateIdentityDocumentNumber.validate(clientData.identityDocumentData.id)) {
      errorList.push('identityDocumentNumber');
    }
  }

  return errorList;
}
