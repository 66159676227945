<template>
  <v-dialog v-model="dialog"
            persistent
            scrollable
            max-width="800px">
    <v-card>
      <v-card-title>
        <v-col>
          Informacja dla klienta dotycząca przekazania danych
        </v-col>
        <v-col cols="3">
          <img v-if="bankCode === 'DRR'"
               src="@/assets/drobnaratka.png"
               alt="Drobnaratka Logo"
               height="40"
               style="vertical-align: middle; display: inline-block">
          <img v-if="bankCode === 'WNG'"
               src="@/assets/wonga.svg"
               alt="Wonga Logo"
               height="40"
               style="vertical-align: middle; display: inline-block">
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div id="clauseForm">
            <div>
              <form name="consentForm">
                <template v-if="disclaimer">
                  <div class="text-body-1 text-pre-wrap">{{disclaimer}}</div>
                </template>
                <v-btn color="primary" outlined x-small @click="selectMandatory">Zaznacz Wymagane</v-btn>
                <template v-if="consentGroups.length>0">
                  <template v-for="(consent, index) in nonGroupConsents">
                    <v-checkbox color="primary" class="mt-2 mb-n2"
                                v-model="consentList[consentIndex(consent.code)].value">
                      <template v-slot:label>
                        <span class="reqStar" v-bind:class="consent.mandatory ? 'required' : ''"/>
                        <consent :consent="consent" class="ml-2"/>
                      </template>
                    </v-checkbox>
                  </template>
                  <template v-for="(consentGroup, groupIndex) in consentGroups">
                    <consent-group :group="consentGroup" :index="groupIndex"/>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(consent, index) in consents">
                    <v-checkbox color="primary" class="mt-2 mb-n2" v-model="consentList[index].value">
                      <template v-slot:label>
                        <span class="reqStar" v-bind:class="consent.mandatory ? 'required' : ''"/>
                        <consent :consent="consent" class="ml-2"/>
                      </template>
                    </v-checkbox>
                  </template>
                </template>
              </form>
            </div>
            <div class="text-center informationClauseSuccessMessage">
              <span class="reqStar required"/>
              <span class="reqDesc ml-1"> - pola wymagane</span>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="cancelClick">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               :class="requiredConsentsChecked ? '' : 'disabled'" @click="okClick">
          Przejdź dalej
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Consent from "./Consent"
  import * as mutations from "@/store/mutations/mutation-types";
  import * as actions from "@/store/actions/action-types";
  import ConsentGroup from "@/components/modals/ConsentGroup";

  export default {
    name: "ConsentModal",
    components: {
      ConsentGroup,
      Consent
    },
    computed: {
      ...mapState([
        'bankCode',
        'consents',
        'consentGroups',
        'consentList',
        'disclaimer'
      ]),
      dialog: {
        get() {
          return this.$store.state.showConsentModal;
        },
        set(val) {
          this.$store.state.showConsentModal = val;
        }
      },
      nonGroupConsents() {
        return this.consents.filter(({ code }) =>
          !this.consentGroups.some(({ consentCodeList }) => consentCodeList.includes(code))
        )
      },
      requiredConsentsChecked() {
        const reqConsents = this.consents
          .filter(({ code, mandatory }) => {
            return mandatory;
          })
          .map(({ code }) => code);

        return this.consentList
          .filter(({ code }) => {
            return reqConsents.includes(code)
          })
          .every(({ value }) => value);
      }
    },
    methods: {
      selectMandatory() {
        for (let i = 0; i < this.consents.length; i++) {
          if (this.consents[i].mandatory) {
            this.consentList[i].value = true;
          }
        }
      },
      consentIndex(consentCode) {
        return this.consentList.findIndex(({ code }) => consentCode === code)
      },
      ...mapMutations({
        okClick: mutations.HIDE_CONSENTS_MODAL
      }),
      ...mapActions({
        cancelClick: actions.CREDIT_BACK
      })
    }
  }
</script>

<style scoped>
  .INB {
    display: inline-block;
    height: 50px;
    width: 100%;
    background: url(/img/inbank.svg) right no-repeat;
    background-size: contain;
  }

  .DRR {
    display: inline-block;
    height: 50px;
    width: 100%;
    background: url(/img/drobnaratka.png) right no-repeat;
    background-size: contain;
  }

  .required::after {
    content: "*";
    color: #ff0000;
    font-size: 16px;
    margin-left: -4px;
    margin-right: -2px;
  }
</style>
