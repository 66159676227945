<template>
  <v-row>
    <v-spacer/>
    <v-col cols="4">
      <div class="text-h4 mb-8 mt-4">Dane do wniosku pożyczkowego</div>
      <v-text-field v-model="identityDocumentNumber"
                    label="Numer dowodu osobistego"
                    type="text"
                    outlined
                    autocomplete="off"
                    :error-messages="errors.first('identityNumber')"
                    name="identityNumber"
                    v-validate="'required||identityDocumentNumber'"/>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import { propertyTypeOptions } from "@/constants";
  import { validateIdentityDocumentNumber } from "@/validators/fieldValidators";
  import { EventBus } from "@/components/event-bus";

  export default {
    name: "CreditDataDrr",
    created() {
      this.$validator.extend('identityDocumentNumber', validateIdentityDocumentNumber);
      EventBus.$on("validate", () => {
        this.$validator.validateAll();
      });
    },
    computed: {
      credit() {
        return this.$store.state.credit
      },
      identityDocumentNumber: {
        get() {
          return this.$store.state.credit.clientData.identityDocumentData.id;
        },
        set(val) {
          this.$store.state.credit.clientData.identityDocumentData.id = val.toUpperCase();
        }
      },
    },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT
      }),
      getPropertyType(type) {
        return propertyTypeOptions.find(({ value }) => value === type).text;
      },
    },
  }
</script>

<style scoped>

</style>
