<template>
  <div>
    <div class="text-h6">Hasło zostało ustawione</div>
    <p>Przejdź na stronę logowania, aby zacząć korzystać z aplikacji.</p>
    <div class="d-flex justify-space-between mb-2">
      <v-btn class="text-none"
             block
             color="primary"
             x-large
             @click="returnToLogin">
        Przejdź do logowania
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SingUpConfirmation",
    methods: {
      returnToLogin() {
        this.$router.push("/login")
      },
    }
  }
</script>

<style scoped>

</style>
