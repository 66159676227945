export const CREATE_USER = "createUserApi";
export const CREATE_AGENCY = "createAgencyApi";
export const EDIT_AGENCY = "editAgencyApi";
export const EDIT_USER = "editUserApi";
export const DISABLE_USER = "disableUserApi";
export const ENABLE_USER = "enableUserApi";
export const SEND_ACTIVATION_LINK = "sendActivationLinkApi";
export const FETCH_USERS = "fetchUsersApi";
export const FETCH_AGENCIES = 'fetchAgenciesApi';
export const FETCH_ACTIVITY_REPORT = 'fetchActivityApi';
export const FETCH_AGENCY_ACTIVITY_REPORT = 'fetchAgencyActivityApi';
