<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Błędy na formularzu
      </v-card-title>
      <v-card-text>
        Na formularzu znajdują się błędy. Popraw je by przejść dalej.
<!--        <template v-for="message in messages">{{message}}<br></template>-->
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="dialog = false">
          Zamknij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ValidationModal",
    computed: {
      dialog: {
        get() {
          return false //this.$store.state.showValidationModal
        },
        set(val) {
          //this.$store.state.showValidationModal = val
        }
      }
    },
  }
</script>
