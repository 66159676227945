<template>
  <v-container>
    <v-row>
      <v-col>
       <installment-calculator/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-6">
        <span class="text-h4">
          Twoje wnioski
        </span>
      </v-col>
      <v-spacer/>
      <v-col class="text-right">
        <v-btn color="primary" x-large @click="credit">Nowy wniosek</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <application-list/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ApplicationList from "@/components/ApplicationList";
  import * as actions from "@/store/actions/action-types";
  import { mapActions } from "vuex";
  import InstallmentCalculator from "@/components/InstallmentCalculator";

  export default {
    name: "Applications",
    components: { InstallmentCalculator, ApplicationList },
    methods: mapActions({
      credit: actions.NEW_APPLICATION
    })
  }
</script>

<style scoped>

</style>
