import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VeeValidate from "vee-validate";
import Maska from 'maska'
import { marked } from 'marked';

Vue.use(Maska);
Vue.config.productionTip = false

VeeValidate.Validator.localize({
  pl: {
    messages: {
      required: () => "Pole wymagane."
    }
  }
});

const config = {
  errorBagName: 'errors', // change if property conflicts
  fieldsBagName: 'fields',
  delay: 0,
  locale: 'pl',
  dictionary: null,
  strict: true,
  classes: false,
  events: 'blur',
  inject: true,
  validity: false,
  aria: true,
  i18n: null, // the vue-i18n plugin instance,
  i18nRootKey: 'validations' // the nested key under which the validation messsages will be located
};

Vue.use(VeeValidate, config);

marked.use({
  pedantic: false,
  gfm: false,
  breaks: false,
});

const markedMixin = {
  methods: {
    md: function (input) {
      return marked(input);
    },
  },
};

Vue.mixin(markedMixin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
