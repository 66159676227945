import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Auth/Login.vue'
import Home from '@/views/Home/Home.vue'
import User from "@/views/Home/User";
import Applications from '@/views/Home/Applications.vue'
import Credit from '@/views/Credit/Credit.vue'
import ClientData from "@/views/Credit/ClientData";
import CreditData from "@/views/Credit/CreditData";
import ConfirmApplication from "@/views/Credit/ConfirmApplication";
import ConfirmContract from "@/views/Credit/ConfirmContract";
import FinalizeApplication from "@/views/Credit/FinalizeApplication";
import Summary from "@/views/Credit/Summary";
import AuthModal from "@/views/Auth/AuthModal";
import ForgotPassword from "@/views/Auth/ForgotPassword";
import ForgotPasswordConfirmation from "@/views/Auth/ForgotPasswordConfirmation";
import SignUp from "@/views/Auth/SignUp";
import SignUpConfirmation from "@/views/Auth/SignUpConfirmation";
import BankChooser from "@/views/Credit/BankChooser";
import FAQ from "@/views/Home/FAQ";
import SignUpFailure from "@/views/Auth/SignUpFailure";
import PasswordRecovery from "@/views/Auth/PasswordRecovery";
import RecoveryFailure from "@/views/Auth/RecoveryFailure";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
    name: "Home",
    children: [
      {
        path: "",
        component: Applications,
        name: "Applications",
      },
      {
        path: "/user",
        component: User,
      },
      {
        path: "/faq",
        component: FAQ,
      }
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Admin.vue'),
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users.vue'),
      },
      {
        path: "agencje",
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Agencies.vue'),
      },
      {
        path: "activity",
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Activity.vue'),
      },
    ]
  },
  {
    path: '/credit',
    component: Credit,
    children: [
      {
        path: "",
        name: "ClientData",
        component: ClientData,
      },
      {
        path: "chooseBank",
        name: "BankChooser",
        component: BankChooser,
      },
      {
        path: "data",
        name: "BeginApplication",
        component: CreditData,
      },
      {
        path: "application",
        name: "ConfirmApplication",
        component: ConfirmApplication,
      },
      {
        path: "contract",
        name: "ConfirmContract",
        component: ConfirmContract,
      },
      {
        path: "finalize",
        name: "Finalize",
        component: FinalizeApplication,
      },
      {
        path: "summary",
        name: "Summary",
        component: Summary,
      },
    ]
  },
  {
    component: AuthModal,
    path: '/',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/zapomnialem_hasla',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/recoveryConfirmation',
        name: 'ForgotPasswordConfirmation',
        component: ForgotPasswordConfirmation,
      },
      {
        path: '/odzyskiwanie_hasla',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
      },
      {
        path: '/failedRecovery',
        name: 'RecoveryFailure',
        component: RecoveryFailure,
      },
      {
        path: '/rejestracja',
        name: 'SignUp',
        component: SignUp,
      },
      {
        path: '/failedRegistration',
        name: 'SignUpFailure',
        component: SignUpFailure,
      },
      {
        path: '/confirmedRegistration',
        name: 'SignUpConfirmation',
        component: SignUpConfirmation,
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const noAuthRequired = [
    "/login",
    "/recoveryConfirmation",
    "/odzyskiwanie_hasla",
    "/rejestracja",
    "/zapomnialem_hasla",
    "/confirmedRegistration",
    "/failedRegistration",
  ];

  const admin = sessionStorage.admin === "true";
  const loggedIn = sessionStorage.loggedIn === "true";
  if (!noAuthRequired.includes(to.path) && !loggedIn) {
    next("/login")
  } else if (noAuthRequired.includes(to.path) && loggedIn) {
    next("/")
  } else if (to.path === "/admin" && !admin) {
    next("/")
  } else {
    next()
  }
});

export default router
