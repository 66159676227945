<template>
  <v-row>
    <v-spacer/>
    <v-col cols="4">
      <div class="text-h4 mb-8 mt-4">Dane do wniosku pożyczkowego</div>
      <v-text-field v-model="identityDocumentNumber"
                    label="Numer dowodu osobistego"
                    type="text"
                    outlined
                    autocomplete="off"
                    :error-messages="errors.first('identityNumber')"
                    name="identityNumber"
                    v-validate="'required||identityDocumentNumber'"/>
      <v-dialog ref="menuIssueDate"
                v-model="menuIssueDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                width="350px"
                min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="parsedIssueDate"
                        v-maska="'##-##-####'"
                        ref="issueDate"
                        label="Data wydania dowodu osobistego"
                        outlined
                        name="identity.issueDate"
                        placeholder="DD-MM-YYYY"
                        :error-messages="errors.first('identity.issueDate')"
                        @focus="issueDateFocus"
                        @blur="issueDateBlur"
                        v-bind="attrs">
            <template v-slot:append>
              <v-icon v-on="on">mdi-calendar</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="issueDate"
                       width="350"
                       locale="pl"
                       :first-day-of-week="1"
                       :max="(new Date(Date.now())).toISOString().substr(0, 10)"
                       min="2001-01-01"
                       @change="saveIssueDate">
        </v-date-picker>
      </v-dialog>
      <v-dialog ref="menuExpiryDate"
                v-model="menuExpiryDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                width="350"
                :disabled="indefinite"
                min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="parsedExpiryDate"
                        v-maska="'##-##-####'"
                        label="Data ważności dowodu osobistego"
                        outlined
                        ref="expiryDate"
                        name="identity.expiryDate"
                        :disabled="indefinite"
                        placeholder="DD-MM-YYYY"
                        @focus="expiryDateFocus"
                        @blur="expiryDateBlur"
                        :error-messages="errors.first('identity.expiryDate')"
                        v-bind="attrs">
            <template v-slot:append>
              <v-icon v-on="on">mdi-calendar</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="expiryDate"
                       locale="pl"
                       :first-day-of-week="1"
                       no-title
                       :max="maxValidDate"
                       :disabled="indefinite"
                       :min="(new Date(Date.now())).toISOString().substr(0, 10)"
                       @change="saveExpiryDate"/>
      </v-dialog>
      <v-checkbox label="Dowód bezterminowy" v-model="indefinite" class="mt-0 mb-4"/>
      <v-select v-model="birthCountry"
                label="Kraj urodzenia"
                outlined
                :items="countriesOptions"
                item-value="code"
                item-text="name"/>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import { propertyTypeOptions, countriesOptions } from "@/constants";
  import { validateIdentityDocumentNumber } from "@/validators/fieldValidators";
  import { EventBus } from "@/components/event-bus";

  export default {
    name: "CreditDataWng",
    created() {
      this.$validator.extend('identityDocumentNumber', validateIdentityDocumentNumber);
      EventBus.$on("validate", () => {
        this.$validator.validateAll();
      });
    },
    data() {
      return {
        countriesOptions: countriesOptions,
        dateIssueDate: null,
        menuIssueDate: false,
        dateExpiryDate: null,
        menuExpiryDate: false,
        parsedIssueDate: "",
        parsedExpiryDate: ""
      }
    },
    computed: {
      credit() {
        return this.$store.state.credit
      },
      identityDocumentNumber: {
        get() {
          return this.$store.state.credit.clientData.identityDocumentData.id;
        },
        set(val) {
          this.$store.state.credit.clientData.identityDocumentData.id = val.toUpperCase();
        }
      },
      expiryDate: {
        get() {
          return this.$store.state.credit.clientData.identityDocumentData.expiryDate;
        },
        set(val) {
          this.$store.state.credit.clientData.identityDocumentData.expiryDate = val;
        }
      },
      issueDate: {
        get() {
          return this.$store.state.credit.clientData.identityDocumentData.issueDate;
        },
        set(val) {
          this.$store.state.credit.clientData.identityDocumentData.issueDate = val;
        }
      },
      indefinite: {
        get() {
          return this.$store.state.credit.clientData.identityDocumentData.indefinite;
        },
        set(val) {
          this.$store.state.credit.clientData.identityDocumentData.indefinite = val
        }
      },
      birthCountry: {
        get() {
          return this.$store.state.credit.clientData.birthCountry;
        },
        set(val) {
          this.$store.state.credit.clientData.birthCountry = val;
        }
      },
      ...mapState({
        stateIssueDate: state => state.credit.clientData.identityDocumentIssueDate
      }),
      maxValidDate() {
        if (isNaN(Date.parse(this.issueDate))) {
          const currentDate = new Date();
          return new Date(currentDate.setFullYear(currentDate.getFullYear() + 10)).toISOString();
        } else {
          const issueDateInner = new Date(this.issueDate);
          return new Date(issueDateInner.setFullYear(issueDateInner.getFullYear() + 10)).toISOString();
        }
      }
    },
    methods: {
      ...mapActions({
        next: actions.CREDIT_NEXT
      }),
      getPropertyType(type) {
        return propertyTypeOptions.find(({ value }) => value === type).text;
      },
      issueDateBlur() {
        if (this.menuIssueDate) {
          return
        }
        if (this.parsedIssueDate === null || this.parsedIssueDate === "") {
          this.errors.add({
            field: "identity.issueDate",
            msg: "Wypełnij pole aby przejść dalej"
          });
          return
        }
        this.issueDate = this.parseDate(this.parsedIssueDate);
        if (this.issueDate === null) {
          this.errors.add({
            field: "identity.issueDate",
            msg: "Niepoprawny format daty"
          });
        } else {
          const issueDate = new Date(this.issueDate);
          if (isNaN(Date.parse(this.issueDate))) {
            this.errors.add({
              field: "identity.issueDate",
              msg: "Niepoprawny format daty"
            });
          } else if (issueDate > Date.now()) {
            this.errors.add({
              field: "identity.issueDate",
              msg: "Data nie może być z przyszłości"
            })
          } else if (issueDate < new Date("2001-01-01")) {
            this.errors.add({
              field: "identity.issueDate",
              msg: "Data nie może być przed 01-01-2001"
            })
          } else {
            this.errors.remove("identity.issueDate")
          }
        }
      },
      issueDateFocus() {
      },
      expiryDateBlur() {
        if (this.menuExpiryDate) {
          return
        }
        if (this.parsedExpiryDate === null || this.parsedExpiryDate === "") {
          this.errors.add({
            field: "identity.expiryDate",
            msg: "Wypełnij pole aby przejść dalej"
          });
          return
        }
        this.expiryDate = this.parseDate(this.parsedExpiryDate);
        if (this.expiryDate === null) {
          this.errors.add({
            field: "identity.expiryDate",
            msg: "Niepoprawny format daty"
          })
        } else {
          const expiryDate = new Date(this.expiryDate);
          if (expiryDate < Date.now()) {
            this.errors.add({
              field: "identity.expiryDate",
              msg: "Data nie może być z przeszłości"
            })
          } else {
            const issueDate = isNaN(Date.parse(this.issueDate)) ? Date.now() : new Date(this.issueDate);
            if (expiryDate > issueDate.setFullYear(issueDate.getFullYear() + 10)) {
              this.errors.add({
                field: "identity.expiryDate",
                msg: "Niepoprawna data ważności"
              })
            } else {
              this.errors.remove("identity.expiryDate")
            }
          }
        }
      },
      expiryDateFocus() {
      },
      saveIssueDate(date) {
        this.$refs.menuIssueDate.save(date);
        setTimeout(() => {
          this.$refs.issueDate.blur();
        }, 0);

      },
      saveExpiryDate(date) {
        this.$refs.menuExpiryDate.save(date);
        setTimeout(() => {
          this.$refs.expiryDate.blur();
        }, 0);
      },
      formatDate(date) {
        if (!date) {
          return null;
        }
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      parseDate(date) {
        const splitDate = date.split(/[\.\-]/);
        if (splitDate.length < 3) {
          return null;
        }
        const [day, month, year] = splitDate;
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
    },
    watch: {
      issueDate(val) {
        this.parsedIssueDate = this.formatDate(this.issueDate)
      },
      expiryDate(val) {
        this.parsedExpiryDate = this.formatDate(this.expiryDate)
      },
    },
  }
</script>

<style scoped>

</style>
