import * as types from './action-types'
import * as mutations from "@/store/mutations/mutation-types";
import * as authTypes from './apiActions/actionTypes/authActionTypes'
import * as adminTypes from './apiActions/actionTypes/adminActionTypes'
import * as creditTypes from './apiActions/actionTypes/creditActionTypes'
import authActions from "./apiActions/authActions";
import adminActions from "./apiActions/adminActions";
import creditActions from "./apiActions/creditActions";
import userActions from "./apiActions/userActions";
import router from "@/router";
import { EventBus } from '@/components/event-bus'
import validateClientData from "@/validators/form/clientData";
import validateFinalizeData from "@/validators/form/finalizeData";
import validateCompanyNip from "@/validators/form/companyNip";
import validateCreditData from "@/validators/form/creditData";

function logAction(name) {
  if (process.env.NODE_ENV === "development") {
    console.log("ACTION", name);
  }
}

const actions = {
  [types.LOGIN]({ commit, dispatch, state }, credentials) {
    logAction(types.LOGIN);
    commit(mutations.SHOW_LOADER);
    dispatch(authTypes.LOGIN, credentials)
  },
  [types.LOGOUT]({ commit, dispatch, state }) {
    logAction(types.LOGOUT);
    commit(mutations.SHOW_LOADER);
    dispatch(authTypes.LOGOUT)
  },
  [types.CREATE_UPDATE_USER]({ commit, dispatch, state }) {
    logAction(types.CREATE_UPDATE_USER);
    commit(mutations.SHOW_LOADER);
    if (state.newUser.id === null) {
      dispatch(adminTypes.CREATE_USER)
    } else {
      dispatch(adminTypes.EDIT_USER)
    }
  },
  [types.CREATE_UPDATE_AGENCY]({ commit, dispatch, state }) {
    logAction(types.CREATE_UPDATE_AGENCY);
    commit(mutations.SHOW_LOADER);
    if (state.newAgency.id === null) {
      dispatch(adminTypes.CREATE_AGENCY)
    } else {
      dispatch(adminTypes.EDIT_AGENCY)
    }
  },
  [types.SEND_ACTIVATION_EMAIL]({ commit, dispatch, state }, id) {
    logAction(types.SEND_ACTIVATION_EMAIL);
    commit(mutations.SHOW_LOADER);
    dispatch(adminTypes.SEND_ACTIVATION_LINK, id)
  },
  [types.EDIT_USER]({ commit, dispatch, state }, srcId) {
    logAction(types.EDIT_USER);
    commit(mutations.SHOW_LOADER);
    state.newUser = state.users.find(({ id }) => id === srcId);
    commit(mutations.HIDE_LOADER);
    commit(mutations.SHOW_CREATE_USER_MODAL)
  },
  [types.EDIT_AGENCY]({ commit, dispatch, state }, srcId) {
    logAction(types.EDIT_AGENCY);
    commit(mutations.SHOW_LOADER);
    state.newAgency = state.agencyList.find(({ id }) => id === srcId);
    commit(mutations.HIDE_LOADER);
    commit(mutations.SHOW_CREATE_AGENCY_MODAL)
  },
  [types.TOGGLE_USER]({ commit, dispatch, state }, { id, status }) {
    logAction(types.TOGGLE_USER);
    commit(mutations.SHOW_LOADER);
    if (status === 'ACTIVE') {
      dispatch(adminTypes.DISABLE_USER, id);
    } else {
      dispatch(adminTypes.ENABLE_USER, id);
    }
  },
  [types.GET_EMAIL_FOR_TOKEN_SIGNUP]({ dispatch }, token) {
    logAction(types.GET_EMAIL_FOR_TOKEN_SIGNUP);
    dispatch(authTypes.GET_EMAIL_FOR_TOKEN_SIGNUP, token)
  },
  [types.GET_EMAIL_FOR_TOKEN_RECOVERY]({ dispatch }, token) {
    logAction(types.GET_EMAIL_FOR_TOKEN_RECOVERY);
    dispatch(authTypes.GET_EMAIL_FOR_TOKEN_RECOVERY, token)
  },
  [types.FETCH_USERS]({ dispatch }) {
    logAction(types.FETCH_USERS);
    dispatch(adminTypes.FETCH_USERS)
  },
  [types.FETCH_AGENCIES]({ dispatch }) {
    logAction(types.FETCH_AGENCIES);
    dispatch(adminTypes.FETCH_AGENCIES)
  },
  [types.FETCH_ACTIVITY_REPORT]({ dispatch }) {
    logAction(types.FETCH_ACTIVITY_REPORT);
    dispatch(adminTypes.FETCH_ACTIVITY_REPORT)
  },
  [types.FETCH_AGENCY_ACTIVITY_REPORT]({ dispatch }) {
    logAction(types.FETCH_AGENCY_ACTIVITY_REPORT);
    dispatch(adminTypes.FETCH_AGENCY_ACTIVITY_REPORT)
  },
  [types.FETCH_APPLICATIONS]({ commit, dispatch }) {
    logAction(types.FETCH_APPLICATIONS);
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.GET_APPLICATIONS)
  },
  [types.SIGN_UP]({ dispatch }, { token, password }) {
    logAction(types.SIGN_UP);
    dispatch(authTypes.SIGN_UP, { token, password })
  },
  [types.FORGOT_PASSWORD]({ dispatch }, { email }) {
    logAction(types.FORGOT_PASSWORD);
    dispatch(authTypes.FORGOT_PASSWORD, { email })
  },
  [types.PASSWORD_RECOVERY]({ dispatch }, { token, password }) {
    logAction(types.PASSWORD_RECOVERY);
    dispatch(authTypes.PASSWORD_RECOVERY, { token, password })
  },
  [types.NEW_APPLICATION]({ state, commit, dispatch }) {
    commit(mutations.CLEAR_CREDIT_DATA);
    commit(mutations.SHOW_LOADER);
    state.currentStep = "DRAW_BANK";
    dispatch(creditTypes.GET_PROCESS_OPTIONS);
  },
  [types.CREDIT_BACK]({ state, commit, dispatch }) {
    switch (state.currentStep) {
      case "DRAW_BANK":
        router.push("/").catch(() => {
        });
        break;
      case "INIT_APPLICATION":
        state.currentStep = "DRAW_BANK";
        router.push({ name: "ClientData" }).catch(() => {
        });
        break;
      case "BEGIN_APPLICATION":
        state.currentStep = "DRAW_BANK";
        router.push({ name: "ClientData" }).catch(() => {
        });
        break;
    }
  },
  [types.CANCEL_APPLICATION]({ commit, dispatch }) {
    logAction(types.CANCEL_APPLICATION);
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.CANCEL_APPLICATION);
  },
  [types.SAVE_APPLICATION]({ commit, dispatch }) {
    logAction(types.SAVE_APPLICATION);
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.SAVE_APPLICATION);
  },
  [types.LOAD_APPLICATION]({ state, commit, dispatch }, { processId }) {
    logAction(types.LOAD_APPLICATION);
    commit(mutations.CLEAR_CREDIT_DATA);
    commit(mutations.SHOW_LOADER);
    state.currentStep = "DRAW_BANK";
    dispatch(creditTypes.LOAD_APPLICATION, processId);
  },
  [types.HIDE_APPLICATION]({ state, commit, dispatch }, { processId }) {
    logAction(types.HIDE_APPLICATION);
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.HIDE_APPLICATION, processId);
    state.applicationList.find(application => application.processId === processId).hidden = true;
  },
  [types.SHOW_APPLICATION]({ state, commit, dispatch }, { processId }) {
    logAction(types.SHOW_APPLICATION);
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.SHOW_APPLICATION, processId);
    state.applicationList.find(application => application.processId === processId).hidden = false;
  },
  async [types.CREDIT_NEXT]({ state, commit, dispatch }) {
    logAction(types.CREDIT_NEXT);
    commit(mutations.SHOW_LOADER);
    switch (state.currentStep) {
      case "DRAW_BANK":
        await dispatch(types.VALIDATE_CLIENT_DATA);
        if (state.formHasErrors === false) {
          dispatch(creditTypes.DRAW_BANK);
        }
        break;
      case "INIT_APPLICATION":
        dispatch(creditTypes.INIT_APPLICATION);
        break;
      case "BEGIN_APPLICATION":
        if (state.credit.confirmation) {
          await dispatch(types.VALIDATE_CREDIT_DATA);
          if (state.formHasErrors === false) {
            dispatch(creditTypes.BEGIN_APPLICATION);
          }
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["By przejść dalej potwierdź tożsamość klienta."]);
          commit(mutations.HIDE_LOADER);
        }
        break;
      case "CONFIRM_APPLICATION":
        dispatch(creditTypes.CONFIRM_APPLICATION);
        break;
      case "WAIT_FOR_DECISION":
        dispatch(creditTypes.WAIT_FOR_DECISION);
        break;
      case "CONFIRM_CONDITIONS":
        dispatch(creditTypes.CONFIRM_CONDITIONS);
        break;
      case "WAIT_FOR_CONTRACT":
        dispatch(creditTypes.WAIT_FOR_CONTRACT);
        break;
      case "CONFIRM_CONTRACT":
        dispatch(creditTypes.CONFIRM_CONTRACT);
        break;
      case "FINALIZE":
        await dispatch(types.VALIDATE_FINALIZE_DATA);
        if (state.formHasErrors === false) {
          dispatch(creditTypes.FINALIZE);
        } else {
          commit(mutations.HIDE_LOADER);
          commit(mutations.SHOW_VALIDATION_MODAL);
        }
        break;
      case "FINISHED":
        router.push("/").catch(() => {
        });
        break;
      case "REJECTED":
        router.push("/").catch(() => {
        });
        break;
      default:
        commit(mutations.HIDE_LOADER);
        commit(mutations.SHOW_ERROR_MODAL, ["Prosimy skontaktować się z wsparcie@hipro.pl"])
    }
  },
  [types.VALIDATE_CLIENT_DATA]({ commit, state }) {
    logAction(types.VALIDATE_CLIENT_DATA);
    state.formHasErrors = true;
    const errors = validateClientData(state.credit, state.user.emailRequired);
    EventBus.$emit("validate");
    if (errors.length === 0) {
      state.formHasErrors = false;
    } else {
      commit(mutations.HIDE_LOADER);
      commit(mutations.SHOW_VALIDATION_MODAL);
    }
  },
  [types.VALIDATE_CREDIT_DATA]({ commit, state }) {
    logAction(types.VALIDATE_CREDIT_DATA);
    state.formHasErrors = true;
    const errors = validateCreditData(state.credit, state.bankCode);
    EventBus.$emit("validate");
    if (errors.length === 0) {
      state.formHasErrors = false;
    } else {
      commit(mutations.HIDE_LOADER);
      commit(mutations.SHOW_VALIDATION_MODAL);
    }
  },
  [types.VALIDATE_FINALIZE_DATA]({ commit, state }) {
    logAction(types.VALIDATE_FINALIZE_DATA);
    state.formHasErrors = true;
    const errors = validateFinalizeData(state.credit);
    EventBus.$emit("validate");
    if (errors.length === 0) {
      state.formHasErrors = false;
    } else {
      commit(mutations.HIDE_LOADER);
      const errorMessages = errors.map((code) => {
        switch (code) {
          case "policyId":
            return "Błędny numer polisy.";
          case "accountNumber":
            return "Błędny numer konta.";
          default:
            return code;
        }
      });
      commit(mutations.SHOW_VALIDATION_MODAL, errorMessages);
    }
  },
  [types.PREPARE_FINALIZE]({ commit, dispatch, state }, { processId, insuranceType }) {
    logAction(types.PREPARE_FINALIZE);
    commit(mutations.SHOW_LOADER);
    commit(mutations.PREPARE_FINALIZE, { processId, insuranceType });
    dispatch(creditTypes.PREPARE_FINALIZE);
  },
  [types.REQUEST_NEW_PIN]({ dispatch }) {
    logAction(types.REQUEST_NEW_PIN);
    dispatch(creditTypes.REQUEST_NEW_PIN);
  },
  [types.CHOOSE_BANK]({ commit, dispatch, state }, { bankCode, isTrasti = false }) {
    logAction(types.CHOOSE_BANK);
    if (state.bankList.length > 0 && state.bankCode !== bankCode) {
      const oldBank = state.bankCode;
      state.bankList.push(oldBank);
      state.bankList = state.bankList.filter(item => item !== bankCode);
      state.bankCode = bankCode;
    }
    state.isTrasti = isTrasti;
    commit(mutations.SHOW_LOADER);
    dispatch(creditTypes.INIT_APPLICATION);
  },
  [types.FIND_COMPANY]({ commit, dispatch, state }) {
    logAction(types.FIND_COMPANY);
    commit(mutations.SHOW_LOADER);
    const errors = validateCompanyNip(state.credit.companyData.nip);
    if (errors.length > 0) {
      commit(mutations.HIDE_LOADER);
      commit(mutations.SHOW_ERROR_MODAL, ["Podany NIP jest niepoprawny."])
    } else {
      dispatch(creditTypes.FIND_COMPANY);
    }
  },
  ...authActions,
  ...adminActions,
  ...creditActions,
  ...userActions
};

export default actions
