<template>
  <div>
    <div class="text-h6">Nie pamiętasz hasła?</div>
    <p>Tutaj możesz je odzyskać.<br>Wpisz adres e-mail konta, do którego chcesz odzyskać dostęp.</p>
    <v-text-field outlined
                  label="Email"
                  v-model="email"/>
    <div class="d-flex justify-space-between mb-2">
      <v-btn class="text-none"
             block
             color="primary"
             x-large
             @click="recoverPassword">
        Odzyskaj hasło
      </v-btn>
    </div>
    <div class="d-flex justify-space-between">
      <v-btn class="text-none"
             block
             x-large
             color="white"
             @click="returnToLogin">
        Wróć do logowania
      </v-btn>
    </div>
  </div>
</template>

<script>
  import * as actions from "@/store/actions/action-types";
  import { mapActions } from "vuex";

  export default {
    name: "ForgotPassword",
    data() {
      return {
        email: null
      }
    },
    methods: {
      returnToLogin() {
        this.$router.push("/login")
      },
      recoverPassword() {
        this.forgotPassword({ email: this.email })
      },
      ...mapActions({
        forgotPassword: actions.FORGOT_PASSWORD
      }),
    }
  }
</script>

<style scoped>

</style>
