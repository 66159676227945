import Vue from 'vue'
import Vuex from 'vuex'

import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...state,
    passwordModal: false,
    emailModal: false,
    cellphoneModal: false,
    userEditModal: false,
  },
  mutations: mutations,
  actions: actions,
  modules: {}
})
