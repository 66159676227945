export default [
  "Volkswagen",
  "Opel",
  "Ford",
  "Audi",
  "Toyota",
  "Skoda",
  "BMW",
  "Renault",
  "Peugeot",
  "Mercedes-Benz",
  "Abarth",
  "Access Motor",
  "Achleitner",
  "Ackermann",
  "Acura",
  "Adria",
  "Aeon",
  "Aixam",
  "Ajp",
  "Alfa Romeo",
  "Alka",
  "Alpha",
  "Alpina",
  "Alpine",
  "Aprilia",
  "Arbos",
  "Arctic Cat",
  "Armatrac",
  "Aro",
  "Asia",
  "Asko",
  "Aston Martin",
  "Autosan",
  "Autosan S.A.",
  "Avia",
  "Bajaj",
  "Barkas",
  "Barton",
  "Belarus",
  "Benelli",
  "Benimar",
  "Bentley",
  "Berger",
  "Bergerecotrail",
  "Beta",
  "Bimota",
  "Bodex",
  "Bova",
  "Brilliance",
  "Bsas Brandys",
  "Bss Metaco",
  "Buell",
  "Buick",
  "Burstner",
  "Cadillac",
  "Cagiva",
  "Can-Am (Bombardier)",
  "Caravelair",
  "Carraro",
  "Cas Crystal",
  "Case Ih",
  "Cbv Blumhardt",
  "Ccm",
  "Cectek",
  "Cezet",
  "Cf Moto",
  "Chateau",
  "Chatenet",
  "Chereau",
  "Chevrolet",
  "Chrysler",
  "Ci-Wilk",
  "Citroën",
  "Claas",
  "Cmt",
  "Cpi",
  "Cristall",
  "Cupra",
  "Dab",
  "Dacia",
  "Daelim",
  "Daew./Ando./Int.",
  "Daewoo",
  "Daewoo-Fso",
  "Daewoo-Fso/Fso",
  "Daf",
  "Daihatsu",
  "Derbi",
  "Dethleffs",
  "Deutz-Fahr",
  "Dfsk",
  "Dniepr",
  "Dodge",
  "Dromech",
  "Ds",
  "Ducati",
  "Eifelland",
  "Enerco",
  "Enfield-Royal",
  "Europard",
  "Farmer",
  "Farmtrac Tractors Eu",
  "Feber/Inter Cars",
  "Fendt",
  "Ferrari",
  "Ferro",
  "Fhu Elrol",
  "Fiat",
  "Fliegl",
  "Fruehauf",
  "Fs Lublin",
  "Fsm",
  "Fso",
  "Gaz",
  "Geo",
  "Gilera",
  "Gmc",
  "Goldoni",
  "Gras",
  "Harley-Davidson",
  "Hattat",
  "Hero Puch",
  "Hobby",
  "Home-Car",
  "Honda",
  "Hummer",
  "Husaberg",
  "Husqvarna",
  "Hymer",
  "Hyosung",
  "Hyundai",
  "Ifa",
  "Igloocar",
  "Ikarus",
  "Indian",
  "Infiniti",
  "Isuzu",
  "Italjet",
  "Iveco",
  "Iveco E.Cargo",
  "Jaguar",
  "Janmil",
  "Jawa",
  "Jeep",
  "Jelcz",
  "Jelcz-Zasada",
  "Jialing",
  "Jincheng/Cpi",
  "Jmc",
  "John Deere",
  "Junak",
  "Kamaz",
  "Karosa",
  "Kassbohrer",
  "Kawasaki",
  "Keeway",
  "Kia",
  "Kingway",
  "Knaus",
  "Kogel",
  "Komar",
  "Koœcian",
  "Kraz",
  "Kreidler",
  "Krone",
  "Ktm",
  "Kubota",
  "Kymco",
  "Lada",
  "Laika",
  "Lamberet",
  "Lamborghini",
  "Lambretta",
  "Lancia",
  "Land Rover",
  "Landini",
  "Lds",
  "Ldv",
  "Ldv-Andoria",
  "Legras",
  "Lexus",
  "Liaz",
  "Lifan",
  "Ligier",
  "Lincoln",
  "Lindner",
  "Linhai",
  "Lmc",
  "Ls",
  "Luaz",
  "Magnus",
  "Mal",
  "Malaguti",
  "Man",
  "Maruti-Suzuki",
  "Maserati",
  "Massey Ferguson",
  "Maybach",
  "Maz",
  "Mazda",
  "Mccormick",
  "Mega",
  "Mercury",
  "Meusburger",
  "Microcar",
  "Mini (Bmw)",
  "Mini (Rover)",
  "Miñsk",
  "Mitsubishi",
  "Mondial",
  "Mondo",
  "Moskwicz",
  "Moto Guzzi",
  "Mtz Belarus Traktor",
  "Muz",
  "Mv Agusta",
  "Mz",
  "Neoplan",
  "New Holland",
  "Nissan",
  "Niu",
  "Novatrail",
  "Nysa",
  "Oldsmobile",
  "Oltcit",
  "Otoyol",
  "Pacton",
  "Pgo",
  "Piaggio",
  "Plymouth",
  "Pol-Mot Warfama",
  "Polaris",
  "Pontiac",
  "Porsche",
  "Prodrent",
  "Pronar",
  "Proton",
  "Raf",
  "Ram",
  "Rieju",
  "Rm Motor",
  "Robur",
  "Rolls-Royce",
  "Roman",
  "Romet",
  "Rossart",
  "Router",
  "Rover",
  "Rover Mg",
  "Saab",
  "Sachs",
  "Same",
  "Saturn",
  "Scania",
  "Scania Irizar",
  "Schmitz",
  "Schwarzmuller",
  "Seat",
  "Setra",
  "Shl Kielce",
  "Siamoto",
  "Simson",
  "Smart",
  "Solaris",
  "Solis",
  "Ssangyong",
  "Star",
  "Stas",
  "Steyr",
  "Subaru",
  "Suzuki",
  "Świdnik",
  "Swm",
  "Sym",
  "Tabbert",
  "Tam",
  "Tarpan",
  "Tata",
  "Tatra",
  "Tema",
  "Tesla",
  "Tgb",
  "Trabant",
  "Trailor",
  "Triumph",
  "Tym",
  "Uaz",
  "Ural",
  "Ursus",
  "Valtra",
  "Van-Hool",
  "Vespa",
  "Victory",
  "Volvo",
  "Władymirec",
  "Wartburg",
  "Waz Lada",
  "Waz Oka",
  "Wfm",
  "Wielton",
  "Wołga",
  "Yamaha",
  "Zaporożec",
  "Zasław",
  "Zastava",
  "Zetor",
  "Zipp",
  "Zoomlion",
  "Zremb Makrum",
  "Zremb Solec",
  "Zremb Wrocław",
  "Zsp Niewiadów",
  "Zx Auto"
];
